import {
  SAVE_PROJECTS,
  CREATE_PROJECT,
  GET_PROJECT,
  DELETE_PROJECT,
  SHARE_PROJECT,
  CLONE_PROJECT,
  UPDATE_PROJECT,
  DISPLAY_PROJECT,
} from "../constants";

import {
  getProjectsAPI,
  createProjectAPI,
  getProjectAPI,
  deleteProjectAPI,
  shareProjectAPI,
  cloneProjectAPI,
  updateProjectAPI
} from "../utils/api-caller";

import {goToProjectsPage} from './app-actions';

import {redirectToLoginPage} from './user-actions';

export function saveProjects(projects) {
  return {
    type: SAVE_PROJECTS,
    projects
  }
}

export function createProject(project) {
  return {
    type: CREATE_PROJECT,
    project
  }
}

export function deleteProject(projectID) {
  return {
    type: DELETE_PROJECT,
    projectID,
  }
}

export function updateProject(project) {
  return {
    type: UPDATE_PROJECT,
    project
  }
}

export function displayProject(project) {
  return {
    type: DISPLAY_PROJECT,
    project
  }
}

export function cloneProject(project) {
  return {
    type: CLONE_PROJECT,
    project
  }
}

export function loadProjectsAndGoToProjectsPage() {
  return function (dispatch, getState, {config: {coreUrl}}) {
    let {session, user} = getState();
    getProjectsAPI(user._id, session.token, coreUrl)
      .then(projectsResponse => {
        dispatch(goToProjectsPage(projectsResponse));
      }, error => {
        if (error.statusCode === 401) {
          dispatch(redirectToLoginPage());
        }
        console.log(error.message);
      });
  }
}

export function callCreateProjectAPI(name) {
  return function (dispatch, getState, {config: {coreUrl}}) {
    let {session, user} = getState();
    createProjectAPI(name, user._id, session.token, coreUrl)
      .then(response => {
        dispatch(createProject(response));
      }, error => {
        if (error.statusCode === 401) {
          dispatch(redirectToLoginPage());
        }
        console.log(error.message);
      });
  }
}

export function callDeleteProjectAPI(projectID) {
  return function (dispatch, getState, {config: {coreUrl}}) {
    let {session, user} = getState();
    deleteProjectAPI(projectID, user._id, session.token, coreUrl)
      .then(response => {
        dispatch(deleteProject(projectID));
      }, error => {
        if (error.statusCode === 401) {
          dispatch(redirectToLoginPage());
        }
        console.log(error.message);
      });
  }
}

export function callUpdateProjectAPI(projectID, newName) {
  return function (dispatch, getState, {config: {coreUrl}}) {
    let {session, user} = getState();
    let currentProject = getState().projects.find(project => {
      return project._id === projectID;
    });

    let creationDate = currentProject.creationDate;
    updateProjectAPI(projectID, newName, user._id, session.token, coreUrl)
      .then(response => {
        console.log(response);
        dispatch(updateProject(response));
      }, error => {
        if (error.statusCode === 401) {
          dispatch(redirectToLoginPage());
        }
        console.log(error.message);
      });
  }
}

export function openProject(projectID) {
  return function (dispatch, getState, {config: {coreUrl}}) {
    let {session, user} = getState();
    getProjectAPI(projectID, user._id, session.token, coreUrl)
      .then(response => {
        console.log(response);
        dispatch(displayProject(response));
      }, error => {
        if (error.statusCode === 401) {
          dispatch(redirectToLoginPage());
        }
        console.log(error.message);
      });
  }
}

export function callCloneProjectAPI(projectID) {
  return function (dispatch, getState, {config: {coreUrl}}) {
    let {session, user, projects} = getState();
    let project = projects.find(project => {
      return project._id === projectID
    });
    createProjectAPI(project.name, user._id, session.token, coreUrl)
      .then(response => {
        dispatch(cloneProject(response));
      }, error => {
        if (error.statusCode === 401) {
          dispatch(redirectToLoginPage());
        }
        console.log(error.message);
      });
  }
}


/*****************************
 TO CLEAN!!!!!!!!!!!!!!!!!!
 /*****************************/




export function shareProject(data) {
  return {
    type: SHARE_PROJECT,
    data
  }
}

export function callShareProjectAPI(projectID, newUserID) {
  return function (dispatch, getState, {config: {coreUrl}}) {
    let session = getState().session.toJS();
    shareProjectAPI(projectID, session, coreUrl)
      .then(response => {
        dispatch(shareProject(response));
      }, error => {
        console.log(error.message);
      });
  }
}

export function getProject(project) {
  return {
    type: GET_PROJECT,
    project
  }
}

export function callGetProjectAPI(projectID) {
  return function (dispatch, getState, {config: {coreUrl}}) {
    let {session, user} = getState();
    getProjectAPI(projectID, user._id, session.token, coreUrl)
      .then(response => {
        console.log(response);
        dispatch(getProject(response.data));
      }, error => {
        console.log(error.message);
      });
  }
}

