import React from 'react';
import PropTypes from 'prop-types';
import FixedDataTable from 'fixed-data-table-2';

const {Table, Column, Cell} = FixedDataTable;

const TextFieldFilter = ({column, filterFunction}) => {
  return (<input type='text' style={{margin:'4px', marginTop:'10px'}}
                 onChange={(event) => {return filterFunction(event, column._id);}}
                 placeholder="filter..."/>);
};

const HeaderCell = ({column, ...props}) => {

  let nameSpan = (<span style={{display:'block', marginLeft:'4px'}}>{column.name}</span>);
  let textFieldFilter = (<TextFieldFilter column={column} filterFunction={props.filterFunction}/>);

  return (<Cell>
    {nameSpan}
    {column.searchable ? textFieldFilter : null}
  </Cell>);
};


const TextCell = ({rowIndex, data, col, ...props}) => {
  return (<Cell {...props}>
    {data[rowIndex][col]}
  </Cell>);
};

class TableView extends React.Component {

  constructor(props) {
    super(props);

    this._columns = this.props.currentTable.header;

    let filteredIndexes = {};

    for (let i = 0; i < this._columns.length; i++) {
      filteredIndexes[this._columns[i]._id] = [];
    }

    for (let n = 0; n < this.props.currentTable.body.length; n++) {
      for (let i = 0; i < this._columns.length; i++) {
        filteredIndexes[this._columns[i]._id].push(n);
      }
    }
    
    this.state = {
      dataList: this.props.currentTable.body,
      filteredDataList: this.props.currentTable.body,
      filteredIndexes: filteredIndexes
    };

    this._onFilterChange = this._onFilterChange.bind(this);
    this._filterOneColumn = this._filterOneColumn.bind(this);
    this._intersect = this._intersect.bind(this);
  }


  _filterOneColumn(value, columnID) {
    if (value === "") {
      // Return all rows
    }
    let filterBy = value.toLowerCase();
    let size = this.state.dataList.length;
    let filteredRows = [];
    let filteredRowsIndices = [];
    for (let index = 0; index < size; index++) {
      let value = this.state.dataList[index][columnID];
      if (value.toLowerCase().indexOf(filterBy) !== -1) {
        filteredRows.push(this.state.dataList[index]);
        filteredRowsIndices.push(index);
      }
    }
    return filteredRowsIndices;
  }

  _intersect(firstArray, secondArray) {
    let firstArrayIndex = 0;
    let secondArrayIndex = 0;
    let result = [];

    while (firstArrayIndex < firstArray.length && secondArrayIndex < secondArray.length) {
      if (firstArray[firstArrayIndex] < secondArray[secondArrayIndex]) {
        firstArrayIndex++;
      } else if (firstArray[firstArrayIndex] > secondArray[secondArrayIndex]) {
        secondArrayIndex++;
      } else {
        result.push(firstArray[firstArrayIndex]);
        firstArrayIndex++;
        secondArrayIndex++;
      }
    }
    return result;
  }

  _onFilterChange(e, columnID) {
    
    let filteredIndexes = this.state.filteredIndexes;
    filteredIndexes[columnID] = this._filterOneColumn(e.target.value, columnID);

    let commonIndexes;

    if (this._columns.length < 2) {
      commonIndexes = filteredIndexes[columnID];
    } else {
      commonIndexes = this._intersect(filteredIndexes[this._columns[0]._id], filteredIndexes[this._columns[1]._id]);
      for (let i = 2; i < this._columns.length - 1; i++) {
        commonIndexes = this._intersect(commonIndexes, filteredIndexes[this._columns[i]._id]);
      }
    }

    let filteredDataList = [];
    commonIndexes.forEach(index => {
      filteredDataList.push(this.state.dataList[index]);
    });

    this.setState({
      filteredIndexes: filteredIndexes,
      filteredDataList: filteredDataList
    });

  }

  render() {
    let {filteredDataList} = this.state;
    return (
      <Table
        rowHeight={50}
        rowsCount={filteredDataList.length}
        headerHeight={70}
        width={this.props.width}
        height={this.props.height}
      >
        {this._columns.map(column =>
          <Column
            key={column._id}
            header={<HeaderCell column={column} filterFunction={this._onFilterChange}/>}
            cell={<TextCell data={filteredDataList} col={column._id}/>}
            width={this.props.width/this._columns.length}
          />
        )}
      </Table>
    );
  }

}

TableView
  .propTypes = {
  currentTable: PropTypes.object.isRequired,
  height: PropTypes.number.isRequired
};

TableView
  .contextTypes = {
  projectActions: PropTypes.object.isRequired,
  appActions: PropTypes.object.isRequired,
};

export
default
TableView;

