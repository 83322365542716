import React from 'react';
import PropTypes from 'prop-types';
import TextField from 'material-ui/TextField';
import Paper from 'material-ui/Paper';
import loginStyle from './register-user-style';
import RaisedButton from 'material-ui/RaisedButton';

class RegisterUserPage extends React.Component {

  constructor(props, context) {
    super(props, context);
    this.changeEmail = this.changeEmail.bind(this);
    this.changePassword = this.changePassword.bind(this);
    this.state = {
      isValid: false,
      emailError: '',
      passwordError: '',
      usernameError: '',
      email: '',
      password: '',
      username: ''
    };
  }

  changeEmail(newEmail) {
    this.setState({email: newEmail});
  }

  validateEmail(newEmail) {

    if (newEmail === '') {
      this.setState({isValid: false});
      this.setState({emailError: this.context.translator.t("The email is required")});
      return;
    }

    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(newEmail)) {
      this.setState({isValid: false});
      this.setState({emailError: this.context.translator.t("Invalid email")});
      return;
    }
    this.setState({isValid: this.state.passwordError === '' && this.state.password !== '' && this.state.usernameError === '' && this.state.username !== ''});
    this.setState({emailError: ""});
  }

  changePassword(newPassword) {

    this.setState({password: newPassword});

    if (newPassword === '') {
      this.setState({isValid: false});
      this.setState({passwordError: this.context.translator.t("Password is required")});
      return;
    }

    this.setState({isValid: this.state.emailError === '' && this.state.email !== '' && this.state.usernameError === '' && this.state.username !== ''});
    this.setState({passwordError: ""});
  }

  changeUsername(newUsername) {

    this.setState({username: newUsername});

    if (newUsername === '') {
      this.setState({isValid: false});
      this.setState({usernameError: this.context.translator.t("Username is required")});
      return;
    }

    this.setState({isValid: this.state.emailError === '' && this.state.email !== '' && this.state.password !== '' && this.state.email !== ''});
    this.setState({usernameError: ""});
  }

  render() {

    return (<div
        style={{backgroundColor: loginStyle.pageStyle.backgroundColor, height:this.props.height, overflowX: loginStyle.pageStyle.overflowX}}>
        <br/><h3 style={{paddingLeft:'10px'}}><b>{this.context.translator.t('Register New User')}</b></h3>
        <Paper style={loginStyle.formStyle} zDepth={2}>
          <TextField
            inputStyle={loginStyle.textStyle}
            hintStyle={loginStyle.hintStyle}
            floatingLabelStyle={loginStyle.floatingLabelStyle}
            underlineStyle={loginStyle.underlineStyle}
            errorText={this.state.usernameError}
            onChange={(event) => this.changeUsername(event.target.value)}
            hintText={this.context.translator.t("Type Username...")}
            floatingLabelText={this.context.translator.t("Username")}
            type="text"
          /><br />
          <TextField
            inputStyle={loginStyle.textStyle}
            hintStyle={loginStyle.hintStyle}
            floatingLabelStyle={loginStyle.floatingLabelStyle}
            underlineStyle={loginStyle.underlineStyle}
            errorText={this.state.emailError}
            onChange={(event) => this.changeEmail(event.target.value)}
            onBlur={(event) => this.validateEmail(event.target.value)}
            hintText={this.context.translator.t("Type email...")}
            floatingLabelText={this.context.translator.t("Email")}
            type="email"
          /><br />
          <TextField
            inputStyle={loginStyle.textStyle}
            hintStyle={loginStyle.hintStyle}
            floatingLabelStyle={loginStyle.floatingLabelStyle}
            underlineStyle={loginStyle.underlineStyle}
            errorText={this.state.passwordError}
            onChange={(event) => this.changePassword(event.target.value)}
            hintText={this.context.translator.t("Type password...")}
            floatingLabelText={this.context.translator.t("Password")}
            type="password"
          />
          <br/>
          <RaisedButton label={this.context.translator.t("Register")} style={loginStyle.buttonStyle}
                        disabled={!this.state.isValid}
                        onClick={() => this.context.userActions.registerUser(this.state.username, this.state.email, this.state.password)}/>
        </Paper>
      </div>
    )
  }
}

RegisterUserPage.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  mode: PropTypes.string.isRequired
};

RegisterUserPage.contextTypes = {
  userActions: PropTypes.object.isRequired,
  translator: PropTypes.object.isRequired
};

export default RegisterUserPage;
