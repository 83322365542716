import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

import TextField from 'material-ui/TextField';
import FlatButton from 'material-ui/FlatButton';

const ENTER_KEY = 13;

class FormUpdateProject extends React.Component {

  constructor(props, context) {
    super(props, context);
    this.changeName = this.changeName.bind(this);
    this.updateProject = this.updateProject.bind(this);
    this.state = {isValid: false, nameError: ''};
  }

  componentDidMount() {
    let textField = ReactDOM.findDOMNode(this.refs.textField);
    textField.querySelector("input").focus();
  }

  changeName(newName) {

    this.setState({name: newName});

    if (newName === '' || newName === this.props.oldName) {
      this.setState({isValid: false});
      this.setState({nameError: this.context.translator.t("You must specify a new name for the project")});
      return;
    }

    this.setState({isValid: true});
    this.setState({nameError: ""});
  }

  updateProject() {
    this.context.projectActions.callUpdateProjectAPI(this.props.projectID, this.state.name);
    this.context.appActions.closeForm();
  }

  render() {
    return (
      <div>
        <TextField
          ref="textField"
          hintText={this.context.translator.t("Enter new name")}
          defaultValue={this.props.oldName}
          fullWidth={true}
          errorText={this.state.nameError}
          onChange={(event) => this.changeName(event.target.value)}
          onKeyDown={event => {if(this.state.isValid && event.keyCode===ENTER_KEY) {this.updateProject()}}}
        />
        <br/>
        <FlatButton
          label={this.context.translator.t("Rename")}
          style={{float:'right'}}
          primary={true}
          disabled={!this.state.isValid}
          onTouchTap={this.updateProject}
        />
        <FlatButton
          label={this.context.translator.t("Cancel")}
          style={{float:'right'}}
          primary={true}
          onTouchTap={this.context.appActions.closeForm}
        />
        <br />
      </div>
    );
  }
}

FormUpdateProject.propTypes = {
  projectID: PropTypes.string.isRequired,
  oldName: PropTypes.string.isRequired,
};

FormUpdateProject.contextTypes = {
  appActions: PropTypes.object.isRequired,
  projectActions: PropTypes.object.isRequired,
  translator: PropTypes.object.isRequired
};

export default FormUpdateProject;
