import 'whatwg-fetch';

import {TYPE_TABLE, TYPE_IMAGE, TYPE_PDF, TYPE_PLAN} from '../constants';

function callAPI(uri, method, sessionToken, params) {

  let requestObject = {
    method,
  };

  if (method !== 'GET' && method !== 'HEAD') {
    if (params instanceof FormData) {
      requestObject.body = params;
    } else {
      requestObject.headers = {
        'Content-Type': 'application/json'
      };
      requestObject.body = JSON.stringify(params);
    }
  }

  if (sessionToken) {
    // Add the token to the request
    uri += `?access_token=${sessionToken}`;
  }

  return fetch(uri, requestObject).then(fetchResponse => {
    if ((fetchResponse.status === 204) && fetchResponse.ok) {
      return Promise.resolve({message: "Operazione eseguita correttamente", statusCode: 200});
    }
    if ((fetchResponse.status === 200) && fetchResponse.ok) {
      if (uri.indexOf('download-file') !== -1) {
        return fetchResponse.blob();
      }
      return fetchResponse.json();
    }
    return new Promise((resolve, reject) => {
      fetchResponse.json().then(data => {
        console.error("Errore nell'elaborazione della richiesta", data);
        alert(`Errore nell'elaborazione della richiesta:\n${data.message}`);
        reject(data.error);
      });
    });
  }, error => {
    console.error(error);
    alert(`Impossibile contattare il server`);
    return Promise.reject({name: "Error", message: "Impossibile contattare il server", statusCode: 500});
  });
}

/***********************************/
/************ ACCOUNTS *************/
/***********************************/

export function userLoginAPI(username, password, coreUrl) {

  let params = { username, password };

  return new Promise((resolve, reject) => {
    return callAPI(`${coreUrl}/user/group-login`, 'POST', undefined, params).then(sessionResponse => {
      console.log(sessionResponse);
      resolve(sessionResponse);
    });
  });
}


export function registerUserAPI(username, email, password, coreUrl) {

  let params = {
    "username": username,
    "email": email,
    "password": password
  };
  return callAPI(`${coreUrl}/accounts/`, 'POST', undefined, params);
}


export function userLogoutAPI(sessionToken, coreUrl) {
  return callAPI(`${coreUrl}/user/logout`, 'POST', sessionToken, {});
}

/***********************************/
/************ PROJECTS *************/
/***********************************/

export function getProjectsAPI(userID, sessionToken, coreUrl) {
  //modded
  return callAPI(`${coreUrl}/project/get-by-owner/${userID}`, 'GET', sessionToken, {});
}

export function createProjectAPI(name, userID, sessionToken, coreUrl) {

  let params = {
    'name': name,
    'owner': userID,
    'created' : Date.now()
  };

  console.log( params );

  return callAPI(`${coreUrl}/project/`, 'POST', sessionToken, params);
}

export function deleteProjectAPI(projectID, userID, sessionToken, coreUrl) {
  return callAPI(`${coreUrl}/project/${projectID}`, 'DELETE', sessionToken, {});
}

export function getProjectAPI(projectID, userID, sessionToken, coreUrl) {
  return new Promise((resolve, reject) => {
    callAPI(`${coreUrl}/project/${projectID}`, 'GET', sessionToken).then(projectResponse => {
      callAPI(`${coreUrl}/project/${projectID}/files`, 'GET', sessionToken).then(projectFiles => {
        projectResponse.files = projectFiles;
        resolve(projectResponse);
      });
    })
  });
}

export function updateProjectAPI(projectID, newName, userID, sessionToken, coreUrl) {
  return callAPI(`${coreUrl}/project/${projectID}`, 'PUT', sessionToken, {name: newName});
}

export function cloneProjectAPI(projectID, session, coreUrl) {
  return createProjectAPI(name, userID, sessionToken, coreUrl)
}

/***********************************/
/************* FILES ***************/
/***********************************/

export function getFilesAPI(projectID, formData, userID, sessionToken, coreUrl) {
  return callAPI(`${coreUrl}/${userID}/files`, 'GET', sessionToken);
}

export function createFileAPI(projectID, formData, userID, sessionToken, coreUrl) {
  return callAPI(`${coreUrl}/accounts/${userID}/upload-file/projects/${projectID}`, 'POST', sessionToken, formData)
}

export function deleteFileAPI(projectID, fileID, userID, sessionToken, coreUrl) {
  return callAPI(`${coreUrl}/plan/${fileID}`, 'DELETE', sessionToken);
}

export function getFileDataAPI(projectID, fileID, userID, sessionToken, coreUrl) {
  return callAPI(`${coreUrl}/accounts/${userID}/download-file/projects/${projectID}/file/${fileID}`, 'GET', sessionToken);
}


/********************* DONE *********************/

/***********************************/
/************* PLANIMETRY ***************/
/***********************************/

export function getPlanimetriesAPI(owner, sessionToken, coreUrl) {
  return callAPI(`${coreUrl}/plans/get-by-owner/${owner}`, 'GET', sessionToken);
}

export function createPlanimetryAPI(owner, name, sessionToken, coreUrl) {
  return callAPI(`${coreUrl}/plan`, 'POST', sessionToken, { owner, name });
}

export function deletePlanimetryAPI(_id, owner, sessionToken, coreUrl) {
  return callAPI(`${coreUrl}/plan/${_id}`, 'DELETE', sessionToken);
}

export function getPlanimetryDataAPI(_id, sessionToken, coreUrl) {
  return callAPI(`${coreUrl}/plan/${_id}`, 'GET', sessionToken);
}


/********************* DONE *********************/


/** TO IMPLEMENT PROJECTS **/
export function shareProjectAPI(projectID, newUser, session, coreUrl) {

  let params = [
    {
      _id: projectID
    },
    {
      user: newUser
    }
  ];

  // return callAPI(`${CORE_URL}/projects/share`, 'POST', session, params);

  /** FAKE DATA **/

  let projectData = {
    success: true
  };

  return Promise.resolve(projectData);
}

/***********************************/
/************** FILES **************/
/***********************************/

export function getFileAPI(projectID, fileID, session, coreUrl) {
  return callAPI(`${coreUrl}/plan/${fileID}`, 'GET', session, params);
}

export function updateFileAPI(projectID, fileID, name, fileType, data, creationDate, session, coreUrl) {

  let currentDate = new Date().toString();

  let params = {
    "name": name,
    "type": fileType,
    "data": data,
    "created": creationDate,
    "modified": currentDate
  };

  params = JSON.parse( JSON.stringify( params ) ); //remove undefined values

  return callAPI(`${coreUrl}/plan/${fileID}`, 'PUT', session, params);

  // /** FAKE DATA **/
  //
  // let currentDate = new Date().toString();
  //
  // let fileData = {
  //   success: true,
  //   data: {
  //     _id: fileID,
  //     name: name,
  //     type: TYPE_IMAGE,
  //     created: "Mon Jun 27 2016 17:18:07 GMT+0200 (CEST)",
  //     modified: currentDate
  //   }
  // };
  // return Promise.resolve(fileData);
}

export function getFileThumbnailAPI(projectID, fileID, width, height, coreUrl) {

  let params = [
    {
      project: projectID
    },
    {
      file: fileID
    },
    {
      width: width
    },
    {
      height: height
    }
  ];

  // return callAPI(`${coreUrl}/files/getThumbnail`, 'GET', session, params);

  /** FAKE DATA **/

  return new Blob()
}

export function updateFileDataAPI(projectID, fileID, data, session, coreUrl) {

  let params = [
    {
      project: projectID
    },
    {
      file: fileID
    },
    {
      data: data
    }
  ];

  // return callAPI(`${coreUrl}/files/update`, 'PUT', session, params);

  /** FAKE DATA **/

  let currentDate = new Date().toString();

  let fileData = {
    success: true,
    data: {
      _id: fileID,
      name: 'myModifiedFile',
      type: TYPE_IMAGE,
      creationDate: "Mon Jun 27 2016 17:18:07 GMT+0200 (CEST)",
      lastModifiedDate: currentDate
    }
  };
  return Promise.resolve(fileData);
}



