import React from 'react';
import PropTypes from 'prop-types';

import Dialog from 'material-ui/Dialog';

import FormCreateProject from './form-create-project.jsx';
import FormShareProject from './form-share-project.jsx';
import FormUpdateProject from './form-update-project.jsx';
import FormUpdateFile from './form-update-file.jsx';
import FormCreatePlan from './form-create-plan.jsx';
import FormUploadFile from './form-upload-file.jsx';

import {
  DIALOG_OPEN,
  CREATE_PROJECT_DIALOG,
  SHARE_PROJECT_DIALOG,
  UPDATE_PROJECT_DIALOG,
  UPDATE_FILE_DIALOG,
  CREATE_PLAN_DIALOG,
  LOAD_FILE_DIALOG,
  LOAD_POINTCLOUD_DIALOG
} from "../../constants";

const FormDialog = ({mode, requestedDialog, dialogProperties}, {projectActions, appActions, translator}) => {

  let form;
  let title;

  switch (requestedDialog) {
    case SHARE_PROJECT_DIALOG:
      form = (<FormShareProject projectID={dialogProperties.get('projectID')}/>);
      title = translator.t('Share this project');
      break;
    case UPDATE_PROJECT_DIALOG:
      form = (
        <FormUpdateProject projectID={dialogProperties.get('projectID')} oldName={dialogProperties.get('oldName')}/>);
      title = translator.t('Rename project');
      break;
    case UPDATE_FILE_DIALOG:
      form = (<FormUpdateFile fileID={dialogProperties.get('fileID')} projectID={dialogProperties.get('projectID')}
                              oldName={dialogProperties.get('oldName')}/>);
      title = translator.t('Rename file');
      break;
    case CREATE_PLAN_DIALOG:
      form = (<FormCreatePlan projectID={dialogProperties.get('projectID')}/>);
      title = translator.t('Create new plan');
      break;
    case LOAD_FILE_DIALOG:
      form = (<FormUploadFile projectID={dialogProperties.get('projectID')}/>);
      title = translator.t('Load new file');
      break;
    case LOAD_POINTCLOUD_DIALOG:
      form = (<FormUploadFile projectID={dialogProperties.get('projectID')}
                              extensions={["las", "laz", "ply", "xyz", "ptx"]}/>);
      title = translator.t('Load new pointcloud');
      break;
    case CREATE_PROJECT_DIALOG:
    default:
      form = (<FormCreateProject/>);
      title = translator.t('Create new project');
  }

  return (
    <Dialog
      title={title}
      modal={false}
      open={mode === DIALOG_OPEN}
      onRequestClose={appActions.closeForm}
      autoScrollBodyContent={true}
    >
      {form}
    </Dialog>
  );
};

FormDialog.propTypes = {
  mode: PropTypes.string.isRequired,
  requestedDialog: PropTypes.string.isRequired,
  dialogProperties: PropTypes.object.isRequired
};

FormDialog.contextTypes = {
  projectActions: PropTypes.object.isRequired,
  appActions: PropTypes.object.isRequired,
  translator: PropTypes.object.isRequired
};

export default FormDialog;
