export default {
  pageStyle: {
    backgroundColor: '#eee',
    overflowX: 'hidden'
  },
  formStyle: {
    margin: '0 auto',
    height: 400,
    width: '50%',
    textAlign: 'center',
    backgroundColor: '#ddd',
  },
  textStyle: {
    color: '#000',
  },
  floatingLabelStyle: {
    color: '#999'
  },
  hintStyle: {
    color: '#bbb'
  },
  underlineStyle: {
    backgroundColor: '#fff'
  },
   buttonStyle: {
     marginTop: 30,
     marginLeft: 170,
   }
};
