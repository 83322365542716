import React from 'react';
import PropTypes from 'prop-types';
import IconButton from 'material-ui/IconButton';

import fileStyles from './file-style';

import FileIcon from 'material-ui/svg-icons/maps/map';

const PlanFileButton = ({projectID, fileID}, {appActions, translator}) => (
  <IconButton
    onTouchTap={() => {appActions.openPlanner(fileID)}}
    style={fileStyles.bigButton}
    iconStyle={fileStyles.bigIcon}
    tooltip={translator.t("Open file")}
    tooltipStyles={fileStyles.tooltipStyle}
  >
    <FileIcon />
  </IconButton>
);

PlanFileButton.propTypes = {
  projectID: PropTypes.string.isRequired,
  fileID: PropTypes.string.isRequired,
};

PlanFileButton.contextTypes = {
  appActions: PropTypes.object.isRequired,
  translator: PropTypes.object.isRequired
};


export default PlanFileButton;
