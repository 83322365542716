import {
  CHANGE_USERBAR_STATUS,
  CHANGE_SIDEBAR_STATUS,
  CHANGE_SNACKBAR_STATUS,
  CHANGE_ADD_FILE_BAR_STATUS,
  CHANGE_PAGE,
  CHANGE_FORM_STATUS,
  OPEN_LOCATION,
  PROJECTS_PAGE,
  LOGIN_PAGE,
  FILES_PAGE,
  REGISTER_USER_PAGE,
  GO_TO_PROJECTS_PAGE,
  GO_TO_FILES_PAGE,
  LOAD_FILE_DIALOG,
  LOAD_POINTCLOUD_DIALOG,
  CREATE_PLAN_DIALOG,
  CREATE_PROJECT_DIALOG,
  UPDATE_PROJECT_DIALOG,
  SHARE_PROJECT_DIALOG,
  UPDATE_FILE_DIALOG
} from '../constants';

import {saveProjects} from './project-actions';
import {getFiles, saveFilesIntoState} from './file-actions';
import {redirectToLoginPage} from "./user-actions";

import {getPlanimetriesAPI} from '../utils/api-caller';

export function changeUserbarStatus() {
  return {
    type: CHANGE_USERBAR_STATUS,
  }
}

export function changeSidebarStatus() {
  return {
    type: CHANGE_SIDEBAR_STATUS,
  }
}

export function changeSnackbarStatus() {
  return {
    type: CHANGE_SNACKBAR_STATUS
  }
}

export function changeAddFileBarStatus() {
  return {
    type: CHANGE_ADD_FILE_BAR_STATUS,
  }
}

function changePage(page) {
  return {
    type: CHANGE_PAGE,
    page
  }
}

function changeFormStatus(requestedDialog, dialogProps) {
  return {
    type: CHANGE_FORM_STATUS,
    requestedDialog,
    dialogProps
  }
}

export function openUpdateFileForm(dialogProps) {
  return changeFormStatus(UPDATE_FILE_DIALOG, dialogProps);
}

export function openUploadFileForm(dialogProps) {
  return changeFormStatus(LOAD_FILE_DIALOG, dialogProps);
}

export function openUploadPointCLoudForm(dialogProps) {
  return changeFormStatus(LOAD_POINTCLOUD_DIALOG, dialogProps);
}

export function openCreatePlanForm(dialogProps) {
  return changeFormStatus(CREATE_PLAN_DIALOG, dialogProps);
}

export function openCreateProjectForm() {
  return changeFormStatus(CREATE_PROJECT_DIALOG);
}

export function openUpdateProjectForm(dialogProps) {
  return changeFormStatus(UPDATE_PROJECT_DIALOG, dialogProps);
}

export function openShareProjectForm(dialogProps) {
  return changeFormStatus(SHARE_PROJECT_DIALOG, dialogProps);
}

export function closeForm() {
  return changeFormStatus();
}

export function goToProjectsPage(projects) {

  let actions = [
    saveProjects(projects),
    changePage(PROJECTS_PAGE)
  ];

  return {
    type: GO_TO_PROJECTS_PAGE,
    actions: actions
  };
}

export function goToLoginPage() {
  return changePage(LOGIN_PAGE)
}

export function goToRegisterUserPage() {
  return changePage(REGISTER_USER_PAGE)
}

export function getAndGoToFilePage() {
  //return changePage(REGISTER_USER_PAGE)
  return function (dispatch, getState, {config: {coreUrl}}) {
    let {session, user} = getState();
    getPlanimetriesAPI(user._id, session.token, coreUrl)
      .then(filesResponse => {

        let actions = [
          saveFilesIntoState(filesResponse),
          changePage(FILES_PAGE)
        ];

        dispatch({
          type: GO_TO_FILES_PAGE,
          actions: actions
        });

      }, error => {
        if (error.statusCode === 401) {
          dispatch(redirectToLoginPage());
        }
        console.log(error.message);
      })
      .catch( console.error );
  };
}

export function goToFilePage() {
  return changePage(FILES_PAGE);
}

export function openPlanner(fileID) {
  return function (dispatch, getState, {config: {plannerUrl}}) {
    let {user, session} = getState();
    dispatch(openLocation(`${plannerUrl}?account=${user._id}&file=${fileID}&access_token=${session.token}`));
  }
}

export function _openPlanner(projectID, fileID) {
  return function (dispatch, getState, {config: {plannerUrl}}) {
    let {user, session} = getState();
    dispatch(openLocation(`${plannerUrl}?account=${user._id}&project=${projectID}&file=${fileID}&access_token=${session.token}`));
  }
}

export function openFileLocation(projectID, fileID) {
  return function (dispatch, getState, {config: {coreUrl}}) {
    let {user, session} = getState();
    dispatch(openLocation(`${coreUrl}/accounts/${user._id}/download-file/projects/${projectID}/file/${fileID}?access_token=${session.token}`));
  }
}

function openLocation(location) {
  window.location.href = location;
  return {
    type: OPEN_LOCATION,
  }
}







