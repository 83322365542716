import React from 'react';
import PropTypes from 'prop-types';
import Paper from 'material-ui/Paper';
import IconButton from 'material-ui/IconButton';

import FileButton from './file-button.jsx';
import UpdateIcon from 'material-ui/svg-icons/editor/mode-edit';
import DownloadIcon from 'material-ui/svg-icons/file/file-download';
import DeleteIcon from 'material-ui/svg-icons/action/delete';

import fileStyles from './file-style';

const File = ({projectID, fileID, name, type}, {fileActions, appActions, translator}) => (

  <Paper style={fileStyles.fileStyle} zDepth={2}>
    <FileButton type={type} projectID={projectID} fileID={fileID}/>
    <div style={fileStyles.fileTitleContainer}><span style={fileStyles.fileTitle}>{name}</span><br/></div>
    <div style={fileStyles.buttonBar}>
      <IconButton
        onTouchTap={() => appActions.openUpdateFileForm({projectID, fileID, oldName:name})}
        style={fileStyles.littleButton}
        iconStyle={fileStyles.littleIcon}
        tooltip={translator.t("Modify file")}
        tooltipStyles={fileStyles.tooltipStyle}
      >
        <UpdateIcon />
      </IconButton>
      {/*<IconButton
        onTouchTap={() => {fileActions.callDownloadFileAPI(projectID, fileID, name)}}
        style={fileStyles.littleButton}
        iconStyle={fileStyles.littleIcon}
        tooltip={translator.t("Download")}
        tooltipStyles={fileStyles.tooltipStyle}
      >
        <DownloadIcon />
      </IconButton>*/}
      <IconButton
        onTouchTap={() => fileActions.callDeleteFileAPI(projectID, fileID)}
        style={fileStyles.littleButton}
        iconStyle={fileStyles.littleIcon}
        tooltip={translator.t("Delete file")}
        tooltipStyles={fileStyles.tooltipStyle}
      >
        <DeleteIcon />
      </IconButton>
    </div>
  </Paper>
);

File.propTypes = {
  projectID: PropTypes.string.isRequired,
  fileID: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
};

File.contextTypes = {
  fileActions: PropTypes.object,
  appActions: PropTypes.object,
  translator: PropTypes.object
};


export default File;
