import {SAVE_USER, SAVE_SESSION, CLEAR_SESSION, CLEAR_USER, REGISTER_USER} from "../constants";
import {User, Session} from '../models';
import {List} from 'immutable';
export default function projectReducer(state, action) {

  switch (action.type) {

    case SAVE_USER:
      let newUser = new User(action.user);
      return state.set('user', newUser);

    case SAVE_SESSION:
      let newSession = new Session({
        token: action.session.token,
        ttl: new Date( action.session.ttl ).getTime(),
        userId: action.session.userId
      });
      return state.set('session', newSession);

    case CLEAR_SESSION:
          return state.set('session', new Session());
    case CLEAR_USER:
      return state.set('user', new User());
    default:
      return state;

  }
}
