import {
  CREATE_FILE,
  GET_FILE,
  DELETE_FILE,
  UPDATE_FILE,
  GET_FILE_THUMBNAIL,
  DOWNLOAD_FILE,
  DISPLAY_FILE,
  UPDATE_FILE_DATA,
  TABLE_PAGE,
  TYPE_PLAN,
  GET_FILES,
  SAVE_FILES_INTO_STATE,
  FILES_PAGE
} from '../constants';

import { Map } from 'immutable';

import * as fileUtils from '../utils/file-utils';

import {
  createFileAPI,
  getFileAPI,
  deleteFileAPI,
  updateFileAPI,
  getFileThumbnailAPI,
  getFileDataAPI,
  updateFileDataAPI,
  createPlanimetryAPI,
  getPlanimetriesAPI
} from '../utils/api-caller';
import {redirectToLoginPage} from "./user-actions";
import {goToFilePage} from './app-actions';

export function createFile(file) {
  return {
    type: CREATE_FILE,
    file
  }
}

export function deleteFile(fileID, done) {
  return {
    type: DELETE_FILE,
    fileID,
    done
  }
}

export function getFile(file) {
  return {
    type: GET_FILE,
    file
  }
}

export function saveFilesIntoState(files) {
  return {
    type: SAVE_FILES_INTO_STATE,
    files
  }
}

export function getFiles() {

  return function (dispatch, getState, {config: {coreUrl}}) {
    let {session, user} = getState();

    getPlanimetriesAPI(user._id, session.token, coreUrl)
    .then(filesResponse => {

      dispatch({
        type: GET_FILES,
        filesResponse
      });

      dispatch(goToFilePage());

    }, error => {
      if (error.statusCode === 401) {
        dispatch(redirectToLoginPage());
      }
      console.log(error.message);
    })
    .catch( console.error );
  }
}

export function downloadFile(data, name) {

  fileUtils.downloadFile(data, name);

  return {
    type: DOWNLOAD_FILE,
    data
  }
}


export function createPlanFile(planName) {
  return function (dispatch, getState, {config: {coreUrl}}) {
    let {session, user} = getState();

    createPlanimetryAPI( user._id, planName, session.token, coreUrl ).then(response => {
      console.log(response);
      dispatch(createFile(response));
    }, error => {
      if (error.statusCode === 401) {
        dispatch(redirectToLoginPage());
      }
      console.log(error.message);
    });

    /*createFileAPI(projectID, formData, user._id, session.token, coreUrl)
      .then(response => {
        console.log(response);
        dispatch(createFile(response));
      }, error => {
        if (error.statusCode === 401) {
          dispatch(redirectToLoginPage());
        }
        console.log(error.message);
      });*/
  }
}

export function createGenericFile(projectID, file, filetype) {
  return function (dispatch, getState, {config: {coreUrl}}) {
    let {session, user} = getState();

    let formData = new FormData();
    formData.append('file', file);
    formData.append('name', file.name);
    formData.append('type', filetype);

    createFileAPI(projectID, formData, user._id, session.token, coreUrl)
      .then(response => {
        console.log(response);
        dispatch(createFile(response));
      }, error => {
        if (error.statusCode === 401) {
          dispatch(redirectToLoginPage());
        }
        console.log(error.message);
      });
  }
}

export function callDeleteFileAPI(projectID, fileID) {
  return function (dispatch, getState, {config: {coreUrl}}) {
    let {session, user} = getState();
    deleteFileAPI(projectID, fileID, user._id, session.token, coreUrl)
      .then(response => {
        dispatch(deleteFile(fileID, response));
      }, error => {
        if (error.statusCode === 401) {
          dispatch(redirectToLoginPage());
        }
        console.log(error.message);
      });
  }
}

export function callDownloadFileAPI(projectID, fileID, filename) {
  return function (dispatch, getState, {config: {coreUrl}}) {
    let {session, user} = getState();
    getFileDataAPI(projectID, fileID, user._id, session.token, coreUrl)
      .then(response => {
        dispatch(downloadFile(response, filename));
      }, error => {
        if (error.statusCode === 401) {
          dispatch(redirectToLoginPage());
        }
        console.log(error.message);
      });
  }
}

/*********** TO CHECK ********************/




export function updateFile(file) {
  return {
    type: UPDATE_FILE,
    file
  }
}

export function getFileThumbnail(thumbnail) {
  return {
    type: GET_FILE_THUMBNAIL,
    thumbnail
  }
}

export function displayFile(data, page) {

  return {
    type: DISPLAY_FILE,
    data,
    page
  }
}

export function updateFileData(file) {
  return {
    type: UPDATE_FILE_DATA,
    file
  }
}

export function callCreateFileAPI(projectID, name, type) {
  return function (dispatch, getState, {config: {coreUrl}}) {
    let session = getState().session.toJS();
    createFileAPI(projectID, name, type, session, coreUrl)
      .then(response => {
        dispatch(createFile(response));
      }, error => {
        if (error.statusCode === 401) {
          dispatch(redirectToLoginPage());
        }
        console.log(error.message);
      });
  }
}

export function callGetFileAPI(projectID, fileID) {
  return function (dispatch, getState, {config: {coreUrl}}) {
    let session = getState().session.toJS();
    getFileAPI(projectID, fileID, session, coreUrl)
      .then(response => {
        dispatch(getFile(response.data));
      }, error => {
        if (error.statusCode === 401) {
          dispatch(redirectToLoginPage());
        }
        console.log(error.message);
      });
  }
}

export function callUpdateFileAPI(projectID, fileID, name) {
  return function (dispatch, getState, {config: {coreUrl}}) {
    let session = getState().session.toJS();

    let currentFile = getState().currentProject.files.find(file => {
      return file._id === fileID;
    });

    console.log( 'AAAA', projectID, fileID, name, currentFile.type, currentFile.data, currentFile.creationDate, session.token, coreUrl );

    updateFileAPI(projectID, fileID, name, currentFile.type, currentFile.data, currentFile.creationDate, session.token, coreUrl)
      .then(response => {



        let newMap = new Map(response);

        console.log('BBBBBBB', response, newMap, newMap.toJS());

        dispatch(updateFile(newMap));
      }, error => {
        if (error.statusCode === 401) {
          dispatch(redirectToLoginPage());
        }
        console.log(error.message);
      });
  }
}

export function callGetFileThumbnailAPI(projectID, fileID, width, height) {
  return function (dispatch, getState, {config: {coreUrl}}) {
    let session = getState().session.toJS();
    getFileThumbnailAPI(projectID, fileID, width, height, session, coreUrl)
      .then(response => {
        dispatch(getFileThumbnail(response));
      }, error => {
        if (error.statusCode === 401) {
          dispatch(redirectToLoginPage());
        }
        console.log(error.message);
      });
  }
}


export function callUpdateFileDataAPI(projectID, fileID, data) {
  return function (dispatch, getState, {config: {coreUrl}}) {
    let session = getState().session.toJS();
    updateFileDataAPI(projectID, fileID, data, session, coreUrl)
      .then(response => {
        dispatch(updateFileData(response.data));
      }, error => {
        if (error.statusCode === 401) {
          dispatch(redirectToLoginPage());
        }
        console.log(error.message);
      });
  }
}

export function callOpenTableAPI(projectID, fileID) {
  return function (dispatch, getState, {config: {coreUrl}}) {
    let session = getState().session.toJS();

    getFileDataAPI(projectID, fileID, session, coreUrl)
      .then(response => {
        dispatch(displayFile(response, TABLE_PAGE));
      }, error => {
        if (error.statusCode === 401) {
          dispatch(redirectToLoginPage());
        }
        console.log(error.message);
      });
  }
}





