import React from 'react';
import PropTypes from 'prop-types';
import {GridList, GridTile} from 'material-ui/GridList';
import FloatingActionButton from 'material-ui/FloatingActionButton';
import ContentAdd from 'material-ui/svg-icons/content/add';
import AddFileBar from './add-file-bar';

import File from './file.jsx'
import Breadcrumb from '../breadcrumb/breadcrumb';

const FilesViewer = ({width, height, currentProject, mode}, {fileActions, appActions, projectActions, theme, translator}) => {

  let names = [{name: translator.t("Home"), action: () => appActions.getAndGoToFilePage()}, {
    name: currentProject.name,
  }];

  return (

    <div>
      <div style={{
        ...theme.background2Style,
        height: height,
        width: width
      }}/>
      <div style={{
        ...theme.logo1Style,
        width: '135px',
        height: '100px'
      }}/>
      <div style={{
        ...theme.logo2Style,
        width: '300px',
        height: '100px',
      }}/>
      <div style={{
        ...theme.background1Style,
        height: height,
        width: '100%',
      }}/>
      <div style={{height: height, overflowX: 'hidden'}}>
        <br/>
        <Breadcrumb names={names}/>
        <GridList
          cellHeight={185}
          cols={width / 170}
          style={{
            overflowY: 'auto',
            backgroundColor: 'rgba(0,0,0,0)',
            paddingBottom: '40px',
            marginLeft: '20px',
            marginRight: '20px'
          }}
        >

          {
            currentProject.files.map((file) => (
              <GridTile key={file._id} style={{overflow: 'visible'}}>
                <File projectID={currentProject._id} fileID={file._id} name={file.name} type={file.type}/>
              </GridTile>
            ))}

          <GridTile>
            <FloatingActionButton _id="AddFilePopoverAnchor" style={{marginLeft: '20px', marginTop: '40px'}}
                                  onTouchTap={() => appActions.openCreatePlanForm()}>
              <ContentAdd />
            </FloatingActionButton>
            <AddFileBar mode={mode} projectID={currentProject._id}/>
          </GridTile>
        </GridList>
      </div>
    </div>
  );
};

FilesViewer.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  mode: PropTypes.string.isRequired,
  currentProject: PropTypes.object.isRequired
};

FilesViewer.contextTypes = {
  fileActions: PropTypes.object.isRequired,
  appActions: PropTypes.object.isRequired,
  projectActions: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  translator: PropTypes.object.isRequired
};


export default FilesViewer;
