import {
  SESSION_KEY,
  SAVE_USER,
  SAVE_SESSION,
  CLEAR_SESSION,
  CLEAR_USER,
  SAVE_USER_AND_GO_TO_FILES_PAGE,
  LOGOUT_AND_GO_TO_LOGIN_PAGE,
  REDIRECT_TO_LOGIN_PAGE
} from "../constants";

import {userLoginAPI, userLogoutAPI, getPlanimetriesAPI, registerUserAPI} from "../utils/api-caller";

import {getAndGoToFilePage, goToLoginPage, changeUserbarStatus} from "./app-actions";
import {getFiles} from './file-actions';

export function saveSession(session) {
  return {
    type: SAVE_SESSION,
    session
  }
}

export function clearSession() {
  return {
    type: CLEAR_SESSION
  }
}

export function clearUser() {
  return {
    type: CLEAR_USER
  }
}

export function saveUser(user) {
  return {
    type: SAVE_USER,
    user
  }
}

export function registerUser(username, email, password) {
  return function (dispatch, getState, {config: {coreUrl}}) {
    registerUserAPI(username, email, password, coreUrl)
      .then(response => {
        console.log(response); // TODO: dispatch something!
      })
      .catch(error => {
        if (error.statusCode === 401) {
          dispatch(redirectToLoginPage());
        }
        console.error(error);
      });
  }
}

function logoutAndGoToLoginPage() {
  sessionStorage.removeItem(SESSION_KEY);

  let actions = [
    clearSession(),
    clearUser(),
    changeUserbarStatus(),
    goToLoginPage()
  ];

  return {
    type: LOGOUT_AND_GO_TO_LOGIN_PAGE,
    actions: actions
  };

}

export function redirectToLoginPage() {
  sessionStorage.removeItem(SESSION_KEY);

  let actions = [
    clearSession(),
    clearUser(),
    goToLoginPage()
  ];

  return {
    type: REDIRECT_TO_LOGIN_PAGE,
    actions: actions
  };
}

function saveUserAndGoToFilesPage(sessionResponse, filesResponse) {

  let user = {
    _id: sessionResponse.user._id,
    email: sessionResponse.user.email,
    username: sessionResponse.user.username
  };

  let session = {
    token: sessionResponse.accessToken,
    ttl: sessionResponse.expiresIn,
    userId: user._id
  };

  sessionStorage.setItem(SESSION_KEY, JSON.stringify({session, user}));

  let actions = [
    saveSession(session),
    saveUser(user),
  ];

  return {
    type: SAVE_USER_AND_GO_TO_FILES_PAGE,
    actions: actions
  };
}

export function userLogin(email, password) {
  return function (dispatch, getState, {config: {coreUrl}}) {
    userLoginAPI(email, password, coreUrl)
      .then(sessionResponse => {
        dispatch(saveUserAndGoToFilesPage(sessionResponse));
        dispatch(getFiles());
      })
      .catch(error => {
        console.error(error);
      });
  }
}

export function userLogout() {
  return function (dispatch, getState, {config: {coreUrl}}) {
    let sessionToken = getState().session.token;
    userLogoutAPI(sessionToken, coreUrl).then(sessionResponse => {
        dispatch(logoutAndGoToLoginPage());
      })
      .catch(error => {
        if (error.statusCode === 401) {
          dispatch(logoutAndGoToLoginPage());
        }
        console.error(error);
      });
  }
}

