import React from 'react';
import PropTypes from 'prop-types';
import ProjectsViewer from './projects-viewer/projects-viewer.jsx';
import FilesViewer from './files-viewer/files-viewer.jsx';
import TablesViewer from './table-viewer/tables-viewer.jsx';
import LoginPage from './login-page/login-page.jsx';
import RegisterUserPage from './register-user-page/register-user-page.jsx';

import {FILES_PAGE, PROJECTS_PAGE, TABLE_PAGE, LOGIN_PAGE, REGISTER_USER_PAGE} from "../constants";

export default function Content({width, height, mode, page, ...rest}) {

  let content;

  switch (page) {
    case FILES_PAGE:
      content = React.createElement(FilesViewer, {width, height, mode, currentProject: rest.currentProject});
      break;
    case TABLE_PAGE:
      content = React.createElement(TablesViewer, {width, height, mode, currentFile: rest.currentFile});
      break;
    case PROJECTS_PAGE:
      content = React.createElement(ProjectsViewer, {width, height, mode, projects: rest.projects});
      break;
    case REGISTER_USER_PAGE:
      content = React.createElement(RegisterUserPage, {width, height, mode});
      break;
    case LOGIN_PAGE:
    default:
      content = React.createElement(LoginPage, {width, height, mode});
  }

  return content;
}

Content.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  page: PropTypes.string.isRequired,
  mode: PropTypes.string.isRequired
};
