import {Record, List, Map} from 'immutable';

export const User = Record({
  _id: "",
  email:"",
  username: ""
});

export const Session = Record({
  token: "",
  ttl: "",
  userId: "",
});

export const File = Record({
  _id: "",
  owner: "",
  name: "",
  type: "",
  filename:"",
  fileSize:0,
  mime:"",
  created:"",
  modified: ""
});

export const Project = Record({
  _id: "",
  name: "",
  owner: "",
  files: new List(),
  created: "",
  modified: ""
});

export const State = Record({
  mode: "",
  page: "",
  requestedDialog: "",
  dialogProperties: new Map(),
  currentProject: new Project(),
  currentFile: new File(),
  user: new User(),
  projects: new List(),
  session: new Session(),
});

