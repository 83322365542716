"use strict";

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import ReactDimensions from 'react-dimensions';

import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import darkBaseTheme from 'material-ui/styles/baseThemes/darkBaseTheme';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import Layout from './layout.jsx';

import * as projectActions from '../actions/project-actions';
import * as fileActions from '../actions/file-actions';
import * as appActions from '../actions/app-actions';
import * as userActions from '../actions/user-actions';

import Translator from '../translator/translator';

import injectTapEventPlugin from 'react-tap-event-plugin';

// Needed for onTouchTap
// http://stackoverflow.com/a/34015469/988941
injectTapEventPlugin();


class App extends React.Component {

  getChildContext() {

    return {
      projectActions: this.props.projectActions,
      fileActions: this.props.fileActions,
      appActions: this.props.appActions,
      userActions: this.props.userActions,
      theme: this.props.theme,
      translator: new Translator(),
    }
  }

  render() {

    let {containerWidth, containerHeight, state} = this.props;

    return <MuiThemeProvider muiTheme={getMuiTheme(darkBaseTheme)}>
      <Layout width={containerWidth}
              height={containerHeight}
              state={state}/>
    </MuiThemeProvider>;
  }
}

App.childContextTypes = {
  projectActions: PropTypes.object,
  fileActions: PropTypes.object,
  appActions: PropTypes.object,
  userActions: PropTypes.object,
  theme: PropTypes.object,
  translator: PropTypes.object
};


function mapStateToProps(state) {
  return {
    state
  };
}

function mapDispatchToProps(dispatch) {
  return {
    projectActions: bindActionCreators(projectActions, dispatch),
    fileActions: bindActionCreators(fileActions, dispatch),
    appActions: bindActionCreators(appActions, dispatch),
    userActions: bindActionCreators(userActions, dispatch),
  }
}

App = connect(mapStateToProps, mapDispatchToProps)(App);
App = ReactDimensions()(App);
export default App;
