export default {
  'Profile': 'Profile',
  'Settings': 'Settings',
  'Sign out': 'Sign out',
  'The email is required': 'The email is required',
  'Invalid email': 'Invalid email',
  'Password is required': 'Password is required',
  'Type email...': 'Type email...',
  'Type password...': 'Type password...',
  'Password': 'Password',
  'Email': 'Email',
  'Code': 'Code',
  'Login': 'Login',
  'Dashboard': 'Dashboard',
  'Projects': 'Projects',
  'Open project': 'Open project',
  'Modify project': 'Modify project',
  'Share with another user': 'Share with another user',
  'Clone project': 'Clone project',
  'Delete project': 'Delete project',
  'Modify file': 'Modify file',
  'Download': 'Download',
  'Delete file': 'Delete file',
  'Open table': 'Open table',
  'Open file': 'Open file',
  'Home': 'Home',
  'Register New User': 'Register New User',
  'Type Username...': 'Type Username...',
  'Username': 'Username',
  'Register': 'Register',
  'Username is required': 'Username is required',
  'Load Point Cloud': 'Load Point Cloud',
  'Load file': 'Load file',
  'New Plan': 'New Plan',
  'You must specify a name for the new plan' : 'You must specify a name for the new plan',
  'Enter name': 'Enter name',
  'Create': 'Create',
  'Cancel': 'Cancel',
  'You must specify a name for the new project' : 'You must specify a name for the new project',
  'Enter user name': 'Enter user name',
  'You must specify a name for the new user': 'You must specify a name for the new user',
  'Share': 'Share',
  'You must specify a new name for the file': 'You must specify a new name for the file',
  'Enter new name': 'Enter new name',
  'Rename': 'Rename',
  'You must specify a new name for the project': 'You must specify a new name for the project',
  'You can only load ': 'You can only load ',
  'files': 'files',
  'Try dropping some files here, or click to select files to upload.': 'Try dropping some files here, or click to select files to upload.',
  'Chosen file:': 'Chosen file:',
  'Create new project': ' Create new project',
  'Share this project': 'Share this project',
  'Rename project': 'Rename project',
  'Rename file': ' Rename file',
  'Create new plan': 'Create new plan',
  'Load new file': 'Load new file',
  'Load new pointcloud': 'Load new pointcloud',
  'The username is required': 'The username is required',
  'BaM Manual': 'BaM Manual'

}
