import React from 'react';
import PropTypes from 'prop-types';
import FloatingActionButton from 'material-ui/FloatingActionButton';
import Popover from 'material-ui/Popover';
import Chip from 'material-ui/Chip';

import PointcloudIcon from 'material-ui/svg-icons/communication/business';
import FileIcon from 'material-ui/svg-icons/editor/insert-drive-file';
import PlanIcon from 'material-ui/svg-icons/maps/map';

import {ADD_FILE_BAR_OPEN} from '../../constants';

const AddFileBar = ({mode, projectID}, {appActions, translator}) => (

  <Popover
    open={mode === ADD_FILE_BAR_OPEN}
    anchorEl={document.getElementById('AddFilePopoverAnchor')}
    anchorOrigin={{vertical: 'center', horizontal: 'right'}}
    targetOrigin={{vertical: 'center', horizontal: 'left'}}
    onRequestClose={appActions.changeAddFileBarStatus}
    style={{backgroundColor:'rgba(0,0,0,0)', boxShadow:'none', marginTop:'0px' }}
  >
    <div style={{display: 'flex'}}>
      <FloatingActionButton style={{margin:'5px'}} mini={true}
                            onTouchTap={() => appActions.openUploadPointCLoudForm({projectID})}>
        <PointcloudIcon />
      </FloatingActionButton><Chip style={{height:'30px'}} labelStyle={{fontSize:'12px'}}>{translator.t('Load Point Cloud')}</Chip>
    </div>

    <div style={{display: 'flex'}}>
      <FloatingActionButton style={{margin:'5px', marginLeft:'25px'}} mini={true}
                            onTouchTap={() => appActions.openUploadFileForm({projectID})}>
        <FileIcon />
      </FloatingActionButton><Chip style={{height:'30px'}} labelStyle={{fontSize:'12px'}}>{translator.t('Load file')}</Chip>
    </div>

    <div style={{display: 'flex'}}>
      <FloatingActionButton style={{margin:'5px', marginLeft:'5px'}} mini={true}
                            onTouchTap={() => appActions.openCreatePlanForm({projectID})}>
        <PlanIcon />
      </FloatingActionButton><Chip style={{height:'30px'}} labelStyle={{fontSize:'12px'}}>{translator.t('New Plan')}</Chip>
    </div>

  </Popover>

);

AddFileBar.propTypes = {
  mode: PropTypes.string.isRequired,
  projectID: PropTypes.string.isRequired
};

AddFileBar.contextTypes = {
  fileActions: PropTypes.object.isRequired,
  appActions: PropTypes.object.isRequired,
  translator: PropTypes.object.isRequired
};


export default AddFileBar;
