import React from 'react';
import PropTypes from 'prop-types';
import TextField from 'material-ui/TextField';
import Paper from 'material-ui/Paper';
import loginStyle from './login-style';
import RaisedButton from 'material-ui/RaisedButton';

class LoginPage extends React.Component {

  constructor(props, context) {
    super(props, context);
    this.changeEmail = this.changeEmail.bind(this);
    this.changePassword = this.changePassword.bind(this);
    this.state = {
      isValid: false,
      emailError: '',
      passwordError: '',
      code: '',
      password: ''
    };
  }

  changeEmail(newEmail) {
    this.setState({code: newEmail});
  }

  validateEmail(newEmail) {

    if (newEmail === '') {
      this.setState({isValid: false});
      this.setState({emailError: this.context.translator.t("The username is required")});
      return;
    }

    this.setState({isValid: this.state.passwordError === '' && this.state.password !== ''});
    this.setState({emailError: ""});
  }

  changePassword(newPassword) {

    this.setState({password: newPassword});

    if (newPassword === '') {
      this.setState({isValid: false});
      this.setState({passwordError: this.context.translator.t("Password is required")});
      return;
    }

    this.setState({isValid: this.state.emailError === '' && this.state.code !== ''});
    this.setState({passwordError: ""});
  }

  render() {

    return (
      <div>
        <div style={{
          ...this.context.theme.background1Style,
          height: this.props.height,
          width: this.props.width,
        }}/>
        <div style={{
          ...this.context.theme.logo1Style,
          width: '135px',
          height: '100px'
        }}/>
        <div style={{
          ...this.context.theme.logo2Style
        }}/>
        <div style={{
          ...this.context.theme.background2Style,
          height: this.props.height,
          width: '100%',
          opacity: 1
        }}/>
        <div
          style={{
            ...loginStyle.pageStyle,
            height: this.props.height
          }}>
          <Paper style={{...loginStyle.formStyle, backgroundColor: this.context.theme.loginFormColor}} zDepth={2}>
            <TextField
              inputStyle={{...loginStyle.textStyle}}
              hintStyle={{...loginStyle.hintStyle}}
              floatingLabelStyle={{...loginStyle.floatingLabelStyle}}
              underlineStyle={{...loginStyle.underlineStyle}}
              errorText={this.state.emailError}
              onChange={(event) => this.changeEmail(event.target.value)}
              onBlur={(event) => this.validateEmail(event.target.value)}
              hintText={this.context.translator.t("Type Username...")}
              floatingLabelText={this.context.translator.t("Username")}
              type="code"
            /><br/>
            <TextField
              inputStyle={{...loginStyle.textStyle}}
              hintStyle={{...loginStyle.hintStyle}}
              floatingLabelStyle={{...loginStyle.floatingLabelStyle}}
              underlineStyle={{...loginStyle.underlineStyle}}
              errorText={this.state.passwordError}
              onChange={(event) => this.changePassword(event.target.value)}
              hintText={this.context.translator.t("Type password...")}
              floatingLabelText={this.context.translator.t("Password")}
              type="password"
            />
            <br/>
            <RaisedButton label={this.context.translator.t("Login")} style={loginStyle.buttonStyle}
                          disabled={!this.state.isValid}
                          onKeyDown={(event) => event.preventDefault()}
                          onClick={() => this.context.userActions.userLogin(this.state.code, this.state.password)}/>
          </Paper>
        </div>
      </div>
    )
  }
}

LoginPage.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  mode: PropTypes.string.isRequired
};

LoginPage.contextTypes = {
  userActions: PropTypes.object.isRequired,
  translator: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

export default LoginPage;
