import React from 'react';
import PropTypes from 'prop-types';

import App from './components/app.jsx';
import initStore from './reducers/store';
import restoreSession from './utils/restore-session';
import checkAuthentication from './utils/check-authentication';

export default class MetiorDashboard extends React.Component {
  componentWillMount() {
    let {config} = this.props;

    let store = initStore({config});
    restoreSession(store);
    checkAuthentication(store);
    this.store = store;
  }

  render() {
    return (
      <App store={this.store} theme={this.props.theme}/>
    )
  }
}

MetiorDashboard.propTypes = {
  config: PropTypes.shape({
    coreUrl: PropTypes.string.isRequired,
    plannerUrl: PropTypes.string.isRequired,
    dashboardUrl: PropTypes.string.isRequired,
    viewerUrl: PropTypes.string.isRequired
  }).isRequired
};
