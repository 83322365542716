import React from 'react';
import PropTypes from 'prop-types';
import Drawer from 'material-ui/Drawer';
import MenuItem from 'material-ui/MenuItem';
import {SIDEBAR_OPEN} from '../../constants'

const Sidebar = ({mode}, {appActions, projectActions, translator}) => (
  <Drawer
    docked={false}
    width={250}
    open={mode === SIDEBAR_OPEN}
    onRequestChange={appActions.changeSidebarStatus}
  >
    <div style={{height: '20px'}}></div>
    <MenuItem onTouchTap={() => {
      appActions.changeSidebarStatus();
      appActions.getAndGoToFilePage()
    }}>{translator.t('Home')}</MenuItem>
  </Drawer>
);

Sidebar.propTypes = {
  mode: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired
};

Sidebar.contextTypes = {
  appActions: PropTypes.object,
  projectActions: PropTypes.object,
  translator: PropTypes.object
};

export default Sidebar;
