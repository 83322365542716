import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

import TextField from 'material-ui/TextField';
import FlatButton from 'material-ui/FlatButton';

import {TYPE_PLAN} from '../../constants';

const ENTER_KEY = 13;

class FormCreatePlan extends React.Component {

  constructor(props, context) {
    super(props, context);
    this.changeName = this.changeName.bind(this);
    this.createPlan = this.createPlan.bind(this);
    this.state = {isValid: false, nameError: ''};
  }

  componentDidMount() {
    let textField = ReactDOM.findDOMNode(this.refs.textField);
    textField.querySelector("input").focus();
  }

  changeName(newName) {

    this.setState({name: newName});
    this.setState({type: TYPE_PLAN});

    if (newName === '') {
      this.setState({isValid: false});
      this.setState({nameError: this.context.translator.t("You must specify a name for the new plan")});
      return;
    }

    this.setState({isValid: true});
    this.setState({nameError: ""});
  }

  createPlan() {
    console.log('AAAAAAA', this.context, this.state );
    this.context.fileActions.createPlanFile(this.state.name);
    this.context.appActions.closeForm();
  }

  render() {
    return (
      <div>
        <TextField
          ref="textField"
          hintText={this.context.translator.t("Enter name")}
          fullWidth={true}
          errorText={this.state.nameError}
          onChange={(event) => this.changeName(event.target.value)}
          onKeyDown={event => {if(this.state.isValid && event.keyCode===ENTER_KEY) {this.createPlan()}}}
        />
        <br/>
        <FlatButton
          label={this.context.translator.t("Create")}
          style={{float:'right'}}
          primary={true}
          disabled={!this.state.isValid}
          onTouchTap={this.createPlan}
        />
        <FlatButton
          label={this.context.translator.t("Cancel")}
          style={{float:'right'}}
          primary={true}
          onTouchTap={this.context.appActions.closeForm}
        />
        <br />
      </div>
    );
  }
}

FormCreatePlan.propTypes = {
};


FormCreatePlan.contextTypes = {
  appActions: PropTypes.object.isRequired,
  fileActions: PropTypes.object.isRequired,
  translator: PropTypes.object.isRequired
};


export default FormCreatePlan;
