// ACTIONS project
export const SAVE_PROJECTS = 'SAVE_PROJECTS';
export const CREATE_PROJECT = 'CREATE_PROJECT';
export const GET_PROJECT = 'GET_PROJECT';
export const DELETE_PROJECT = 'DELETE_PROJECT';
export const SHARE_PROJECT = 'SHARE_PROJECT';
export const CLONE_PROJECT = 'CLONE_PROJECT';
export const UPDATE_PROJECT = 'UPDATE_PROJECT';
export const DISPLAY_PROJECT = 'DISPLAY_PROJECT';

// ACTIONS file
export const SAVE_FILES_INTO_STATE = 'SAVE_FILES_INTO_STATE';
export const CREATE_FILE = 'CREATE_FILE';
export const GET_FILE = 'GET_FILE';
export const GET_FILES = 'GET_FILES';
export const DELETE_FILE = 'DELETE_FILE';
export const UPDATE_FILE = 'UPDATE_FILE';
export const GET_FILE_THUMBNAIL = 'GET_FILE_THUMBNAIL';
export const DOWNLOAD_FILE = 'DOWNLOAD_FILE';
export const UPDATE_FILE_DATA = 'UPDATE_FILE_DATA';
export const DISPLAY_FILE = 'DISPLAY_FILE';

// ACTIONS user
export const SAVE_USER = 'SAVE_USER';
export const SAVE_SESSION = 'SAVE_SESSION';
export const CLEAR_SESSION = 'CLEAR_SESSION';
export const CLEAR_USER = 'CLEAR_USER';
export const SAVE_USER_AND_GO_TO_FILES_PAGE = 'SAVE_USER_AND_GO_TO_FILES_PAGE';
export const LOGOUT_AND_GO_TO_LOGIN_PAGE = 'LOGOUT_AND_GO_TO_LOGIN_PAGE';
export const REDIRECT_TO_LOGIN_PAGE = 'REDIRECT_TO_LOGIN_PAGE';
export const REGISTER_USER = 'REGISTER_USER';

// ACTIONS app
export const CHANGE_USERBAR_STATUS = 'CHANGE_USERBAR_STATUS';
export const CHANGE_SIDEBAR_STATUS = 'CHANGE_SIDEBAR_STATUS';
export const CHANGE_ADD_FILE_BAR_STATUS = 'CHANGE_ADD_FILE_BAR_STATUS';
export const CHANGE_PAGE = 'CHANGE_PAGE';
export const CHANGE_SNACKBAR_STATUS = 'CHANGE_SNACKBAR_STATUS';
export const CHANGE_FORM_STATUS = 'CHANGE_FORM_STATUS';
export const OPEN_LOCATION = 'OPEN_LOCATION';
export const GO_TO_PROJECTS_PAGE = 'GO_TO_PROJECTS_PAGE';
export const GO_TO_FILES_PAGE = 'GO_TO_FILES_PAGE';

// MODES
export const IDLE = 'IDLE';
export const USERBAR_OPEN = 'USERBAR_OPEN';
export const SIDEBAR_OPEN = 'SIDEBAR_OPEN';
export const DIALOG_OPEN = 'DIALOG_OPEN';
export const SNACKBAR_OPEN = 'SNACKBAR_OPEN';
export const ADD_FILE_BAR_OPEN = 'ADD_FILE_BAR_OPEN';

// PAGES
export const LOGIN_PAGE = 'LOGIN_PAGE';
export const PROJECTS_PAGE = 'PROJECTS_PAGE';
export const FILES_PAGE = 'FILES_PAGE';
export const TABLE_PAGE = 'TABLE_PAGE';
export const REGISTER_USER_PAGE = 'REGISTER_USER_PAGE';

// FILE TYPE
export const TYPE_IMAGE = 'TYPE_IMAGE';
export const TYPE_PDF = 'TYPE_PDF';
export const TYPE_PLAN = 'TYPE_PLAN';
export const TYPE_POINT_CLOUD = 'TYPE_POINT_CLOUD';
export const TYPE_FILE = 'TYPE_FILE';
export const TYPE_TABLE = 'TYPE_TABLE';

// REQUESTED DIALOGS
export const SHARE_PROJECT_DIALOG = 'SHARE_DIALOG';
export const CREATE_PROJECT_DIALOG = 'CREATE_PROJECT_DIALOG';
export const UPDATE_PROJECT_DIALOG = 'UPDATE_PROJECT_DIALOG';
export const UPDATE_FILE_DIALOG = 'UPDATE_FILE_DIALOG';
export const CREATE_PLAN_DIALOG = 'CREATE_PLAN_DIALOG';
export const LOAD_FILE_DIALOG = 'LOAD_FILE_DIALOG';
export const LOAD_POINTCLOUD_DIALOG = 'LOAD_POINTCLOUD_DIALOG';

// OTHERS
export const SESSION_KEY = 'metior-dashboard-session';
