export default {
  'Profile': 'Profilo',
  'Settings': 'Impostazioni',
  'Sign out': 'Esci',
  'The email is required': 'L\'email è obbligatoria',
  'Invalid email': 'Email non valida',
  'Password is required': 'La password è obbligatoria',
  'Type email...': 'Inserisci l\'email...',
  'Type password...': 'Inserisci la password...',
  'Password': 'Password',
  'Email': 'Email',
  'Code': 'Codice',
  'Login': 'Login',
  'Dashboard': 'Dashboard',
  'Projects': 'Progetti',
  'Open project': 'Apri',
  'Modify project': 'Rinomina',
  'Share with another user': 'Condividi con un altro utente',
  'Clone project': 'Duplica',
  'Delete project': 'Elimina',
  'Modify file': 'Modifica file',
  'Download': 'Scarica',
  'Delete file': 'Elimina file',
  'Download file': 'Scarica file',
  'Open file': 'Apri file',
  'Open table': 'Apri tabella',
  'Home': 'Home',
  'Register New User': 'Registra un nuovo utente',
  'Type Username...': 'Inserisci il nome utente...',
  'Username': 'Nome utente',
  'Register': 'Registra',
  'Username is required': 'Il nome utente è obbligatorio',
  'Load Point Cloud': 'Carica Point Cloud',
  'Load file': 'Carica file',
  'New Plan': 'Nuova planimetria',
  'You must specify a name for the new plan': 'Devi specificare un nome per la nuova planimetria',
  'Enter name': 'Inserisci il nome',
  'Create': 'Crea',
  'Cancel': 'Annulla',
  'You must specify a name for the new project': 'Devi specificare un nome per il nuovo progetto',
  'Enter user name': 'Inserisci il nome utente',
  'You must specify a name for the new user': 'Devi specificare un nome per l\'utente',
  'Share': 'Condividi',
  'You must specify a new name for the file': 'Devi specificare un nuovo nome per il file',
  'Enter new name': 'Inserisci il nuovo nome',
  'Rename': 'Rinomina',
  'You must specify a new name for the project': 'Devi specificare un nuovo nome per il progetto',
  'You can only load ': 'Puoi caricare solo ',
  'files': 'files',
  'Try dropping some files here, or click to select files to upload.': 'Trascina qui i tuoi file o clicca per selezionare i file da caricare.',
  'Chosen file:': 'File scelto:',
  'Create new project': ' Crea un nuovo progetto',
  'Share this project': 'Condividi questo progetto',
  'Rename project': 'Rinomina il progetto',
  'Rename file': ' Rinomina il file',
  'Create new plan': 'Crea una nuova planimetria',
  'Load new file': 'Carica un file',
  'Load new pointcloud': 'Carica una nuova pointcloud',
  'The username is required': 'Il nome utente è richiesto',
  'BaM Manual': 'Manuale BaM'
}
