import React from 'react';
import PropTypes from 'prop-types';

import IconButton from 'material-ui/IconButton';
import BackIcon from 'material-ui/svg-icons/navigation/arrow-back';

import TableView from './table-view.jsx';

import {FILES_PAGE} from '../../constants';

const TablesViewer = ({width, height, currentProject, currentFile, mode}, {appActions}) => (

  <div style={{backgroundColor:'#eee', height:height}}>
    <br/>
    <h3 style={{paddingLeft:'10px'}}>
      <IconButton
        onTouchTap={() => appActions.goToFilesPage()}
        style={{width: 64,height: 64}}
        iconStyle={{width: 32, height: 32, fill: '#959595'}}
      >
        <BackIcon />
      </IconButton>
      <b>{currentFile.name}</b>
    </h3>
    <div style={{margin:'20px'}}>
    <TableView currentTable={currentFile.data} width={width - 40} height={height - 150}/>
    </div>
  </div>
);

TablesViewer.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  mode: PropTypes.string.isRequired,
  currentFile: PropTypes.object.isRequired
};

TablesViewer.contextTypes = {
  appActions: PropTypes.object.isRequired,
};

export default TablesViewer;
