//BAM THEME

const backgroundImages = [require('./images/home-bg.png'), require('./images/square-bg.jpg'), require('./images/logo-cng.png'), require('./images/logo-geoweb.png')];
 module.exports = {
  themeColor: 'rgb(245, 244, 226)',
  loginFormColor: 'rgba(221,221,221,0.70)',
  background1Style: {
    background: `url("${backgroundImages[1]}")`,
    position: 'absolute',
    zIndex: -9999
  },
  background2Style: {
    background: `url("${backgroundImages[0]}") no-repeat bottom left`,
    backgroundSize: 'contain',
    position: 'absolute',
    opacity: '0.3',
    zIndex: -9998
  },
  logo1Style: {
    background: `url("${backgroundImages[3]}") no-repeat`,
    backgroundSize: "contain",
    position: 'absolute',
    top: '15%',
    right: '2%'
  },
  logo2Style: {
    background: `#fff url("${backgroundImages[2]}") 0px 50% no-repeat`,
    backgroundSize: "contain",
    position: 'absolute',
    bottom: '2%',
    right: '1%',
    width: '200px',
    height: '50px',
    boxShadow: '0px 0px 10px #000',
    borderRadius: '5px'
  }
};
