import React from 'react';
import PropTypes from 'prop-types';
import IconButton from 'material-ui/IconButton';

import fileStyles from './file-style';

import FileIcon from 'material-ui/svg-icons/editor/insert-drive-file';

const GenericFileButton = ({projectID, fileID}, {appActions, translator}) => (
  <IconButton
    onTouchTap={() => {appActions.openFileLocation(projectID, fileID)}}
    style={fileStyles.bigButton}
    iconStyle={fileStyles.bigIcon}
    tooltip={translator.t("Download file")}
    tooltipStyles={fileStyles.tooltipStyle}
  >
    <FileIcon />
  </IconButton>
);

GenericFileButton.propTypes = {
  projectID: PropTypes.string.isRequired,
  fileID: PropTypes.string.isRequired,
};

GenericFileButton.contextTypes = {
  appActions: PropTypes.object.isRequired,
  translator: PropTypes.object.isRequired
};


export default GenericFileButton;
