import {SESSION_KEY} from '../constants';
import {saveSession, saveUser} from '../actions/user-actions';
import {getAndGoToFilePage} from '../actions/app-actions';

export default function restoreSession(store) {

  let session = JSON.parse(sessionStorage.getItem(SESSION_KEY));

  if (session && store.getState().session.token === "") {
    store.dispatch(saveSession(session.session));
    store.dispatch(saveUser(session.user));
    store.dispatch(getAndGoToFilePage());
  }
}
