import {userLogout} from '../actions/user-actions';
import {goToLoginPage} from '../actions/app-actions';
import {LOGIN_PAGE} from '../constants';

export default function checkAuthentication(store) {

  store.subscribe(() => {
    let {session, user, page} = store.getState();

    if(page !== "" && page !== LOGIN_PAGE) {
      if ((session.token === "" || user._id === "")) {
        store.dispatch(goToLoginPage())
      } else if (session.userId !== user._id || session.ttl <= Date.now()) {
        store.dispatch(userLogout())
      }
    }
  });
}
