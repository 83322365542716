import {List} from 'immutable';
import {File} from '../models';
import {
  CREATE_FILE,
  GET_FILE,
  GET_FILES,
  DELETE_FILE,
  UPDATE_FILE,
  GET_FILE_THUMBNAIL,
  DOWNLOAD_FILE,
  UPDATE_FILE_DATA,
  DISPLAY_FILE,
  SAVE_FILES_INTO_STATE
} from '../constants';

export default function (state, action) {

  let newFile;

  switch (action.type) {

    case SAVE_FILES_INTO_STATE:
      let plans = action.files.map( el => new File({
        _id: el._id,
        name: el.name,
        type: el.type,
        filename: el.filename,
        fileSize: el.size,
        mime: el.mime,
        created: el.created,
        modified: el.modified
      }) );

      return state.setIn(['currentProject', 'files'], new List(plans) );

    case CREATE_FILE:
      newFile = new File({
        _id: action.file._id,
        name: action.file.name,
        type: action.file.type,
        filename: action.file.filename,
        fileSize: action.file.size,
        mime: action.file.mime,
        created: action.file.created,
        modified: action.file.modified
      });

      return state.updateIn(['currentProject', 'files'], files => {
        return files.push(newFile);
      });

    case GET_FILE:

      newFile = new File({
        _id: action.file._id,
        name: action.file.name,
        type: action.file.type,
        filename: action.file.filename,
        fileSize: action.file.size,
        mime: action.file.mime,
        created: action.file.created,
        modified: action.file.modified
      });

      return state.set('currentFile', newFile);

    case GET_FILES:
      let arr = action.filesResponse.map( el => { return new File({
        _id: el._id,
        name: el.name,
        created: el.created,
        modified: el.modified
      }); } );

      return state.setIn(['currentProject', 'files'], new List(arr));

    case DELETE_FILE:

      if (action.done) {
        return state.updateIn(['currentProject', 'files'], files => {
          return files.filter(file => {
            return file._id !== action.fileID
          });
        });
      }
      return state;

    case UPDATE_FILE:
      return state.updateIn(
        ['currentProject', 'files'],
        files =>
        {
          return state.currentProject.files.update(
            state.currentProject.files.findIndex((file) => {
              return file._id === action.file._id;
            }),
            (file) => file.merge(action.file)
          )
        }
      );

    case UPDATE_FILE_DATA:

      return state.updateIn(['currentProject', 'files'], files => state.currentProject.files.update(
        state.currentProject.files.findIndex(function (file) {
          return file._id === action.file._id;
        }), function (file) {
          return file
            .set('_id', action.file._id)
            .set('name', action.file.name)
            .set('type', action.file.type)
            .set('fileSize', action.file.size)
            .set('mime', action.file.mime)
            .set('created', action.file.created)
            .set('modified', action.file.modified);
        }
      ));

    case DISPLAY_FILE:
      return state.setIn(['currentFile', 'data'], action.data).set('page', action.page);
    case GET_FILE_THUMBNAIL:
    case DOWNLOAD_FILE:
    default:
      return state;

  }
}
