import React from 'react';
import PropTypes from 'prop-types';
import Paper from 'material-ui/Paper';
import IconButton from 'material-ui/IconButton';

import FolderIcon from 'material-ui/svg-icons/file/folder';
import UpdateIcon from 'material-ui/svg-icons/editor/mode-edit';
import ShareIcon from 'material-ui/svg-icons/social/share';
import CopyIcon from 'material-ui/svg-icons/content/content-copy';
import DeleteIcon from 'material-ui/svg-icons/action/delete';

import projectStyles from './project-style';

const Project = ({_id, name}, {projectActions, appActions, translator}) => (
  <Paper style={projectStyles.projectStyle} zDepth={2}>
    <IconButton
      onTouchTap={() => {projectActions.openProject(_id);}}
      style={projectStyles.bigButton}
      iconStyle={projectStyles.bigIcon}
      tooltip={translator.t("Open project")}
      tooltipStyles={projectStyles.tooltipStyle}
    >
      <FolderIcon />
    </IconButton>
    <div style={projectStyles.projectTitleContainer}><span
      style={projectStyles.projectTitle}>{name}</span><br/></div>
    <div style={projectStyles.buttonBar}>
      <IconButton
        onTouchTap={() => appActions.openUpdateProjectForm({projectID:_id, oldName:name})}
        style={projectStyles.littleButton}
        iconStyle={projectStyles.littleIcon}
        tooltip={translator.t("Modify project")}
        tooltipStyles={projectStyles.tooltipStyle}
      >
        <UpdateIcon />
      </IconButton>
      <IconButton
        onTouchTap={() => appActions.openShareProjectForm({projectID:_id})}
        style={projectStyles.littleButton}
        iconStyle={projectStyles.littleIcon}
        tooltip={translator.t("Share with another user")}
        tooltipStyles={projectStyles.tooltipStyle}
      >
        <ShareIcon />
      </IconButton>
      <IconButton
        onTouchTap={() => projectActions.callCloneProjectAPI(_id)}
        style={projectStyles.littleButton}
        iconStyle={projectStyles.littleIcon}
        tooltip={translator.t("Clone project")}
        tooltipStyles={projectStyles.tooltipStyle}
      >
        <CopyIcon />
      </IconButton>
      <IconButton
        onTouchTap={() => projectActions.callDeleteProjectAPI(_id)}
        style={projectStyles.littleButton}
        iconStyle={projectStyles.littleIcon}
        tooltip={translator.t("Delete project")}
        tooltipStyles={projectStyles.tooltipStyle}
      >
        <DeleteIcon />
      </IconButton>
    </div>
  </Paper>
);

Project.propTypes = {
  _id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
};

Project.contextTypes = {
  projectActions: PropTypes.object,
  appActions: PropTypes.object,
  translator: PropTypes.object.isRequired,
};

export default Project;
