const backgroundImages = [require('./images/logo-geoweb.png')];
module.exports = {
  themeColor: '#eee',
  loginFormColor: '#ddd',
  background1Style: {
    background: `#eee`,
    position: 'absolute',
    zIndex: -9999
  },
  background2Style: {
    background: `#eee`,
    backgroundSize: 'contain',
    position: 'absolute',
    opacity: '0.5',
    zIndex: -9998
  },
  logo1Style: {
    background: `url("${backgroundImages[0]}") no-repeat`,
    backgroundSize: "contain",
    position: 'absolute',
    top: '15%',
    right: '2%'
  },
  logo2Style: {
    background: `rgba(0,0,0,0)`,
    backgroundSize: "contain",
    position: 'absolute',
    bottom: '2%',
    right: '1%',
    width: '300px',
    height: '100px'
  }
};
