import React from 'react';
import PropTypes from 'prop-types';
import PDFFileButton from './pdf-file-button.jsx';
import ImageFileButton from './image-file-button.jsx';
import PlanFileButton from './plan-file-button.jsx';
import TableFileButton from './table-file-button.jsx';
import GenericFileButton from './generic-file-button.jsx';

import {TYPE_PDF, TYPE_IMAGE, TYPE_PLAN, TYPE_TABLE} from "../../constants";

export default function FileButton({type, projectID, fileID}) {
  return React.createElement(PlanFileButton, {projectID, fileID});
}

FileButton.propTypes = {
  projectID: PropTypes.string.isRequired,
  fileID: PropTypes.string.isRequired,
};


