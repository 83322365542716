import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

import TextField from 'material-ui/TextField';
import FlatButton from 'material-ui/FlatButton';

const ENTER_KEY = 13;

class FormUpdateFile extends React.Component {

  constructor(props, context) {
    super(props, context);
    this.changeName = this.changeName.bind(this);
    this.updateFile = this.updateFile.bind(this);
    this.state = {isValid: false, nameError: ''};
  }

  componentDidMount() {
    let textField = ReactDOM.findDOMNode(this.refs.textField);
    textField.querySelector("input").focus();
  }

  changeName(newName) {

    this.setState({name: newName});

    if (newName === '' || newName === this.props.oldName) {
      this.setState({isValid: false});
      this.setState({nameError: this.context.translator.t("You must specify a new name for the file")});
      return;
    }

    this.setState({isValid: true});
    this.setState({nameError: ""});
  }

  updateFile() {
    this.context.fileActions.callUpdateFileAPI(this.props.projectID, this.props.fileID, this.state.name);
    this.context.appActions.closeForm();
  }

  render() {
    return (
      <div>
        <TextField
          ref="textField"
          hintText={this.context.translator.t("Enter new name")}
          defaultValue={this.props.oldName}
          fullWidth={true}
          errorText={this.state.nameError}
          onChange={(event) => this.changeName(event.target.value)}
          onKeyDown={event => {if(this.state.isValid && event.keyCode===ENTER_KEY) {this.updateFile()}}}
        />
        <br/>
        <FlatButton
          label={this.context.translator.t("Rename")}
          style={{float:'right'}}
          primary={true}
          disabled={!this.state.isValid}
          onTouchTap={this.updateFile}
        />
        <FlatButton
          label={this.context.translator.t("Cancel")}
          style={{float:'right'}}
          primary={true}
          onTouchTap={this.context.appActions.closeForm}
        />
        <br />
      </div>
    );
  }
}

FormUpdateFile.propTypes = {
  projectID: PropTypes.string.isRequired,
  fileID: PropTypes.string.isRequired,
  oldName: PropTypes.string.isRequired,
};

FormUpdateFile.contextTypes = {
  appActions: PropTypes.object.isRequired,
  fileActions: PropTypes.object.isRequired,
  translator: PropTypes.object
};

export default FormUpdateFile;
