import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';

import FlatButton from 'material-ui/FlatButton';

import {TYPE_FILE, TYPE_IMAGE, TYPE_POINT_CLOUD, TYPE_PDF} from '../../constants';

// const IMG_EXT = ['png', 'jpeg', 'jpg'];

class FormUploadFile extends React.Component {

  constructor(props, context) {
    super(props, context);
    this.changeFile = this.changeFile.bind(this);
    this.createFile = this.createFile.bind(this);
    this.state = {isValid: false, nameError: ''};
  }

  changeFile(files) {

    this.setState({file: files[0]});

    let extension = files[0].name.split('.').pop();

    if (this.props.extensions) {
      console.log(extension, files[0], this.props.extensions, this.props.extensions.indexOf(extension) !== -1);
      // Here I will check for the correct extension (or I can set it on the component?)
      if (!(this.props.extensions.indexOf(extension) !== -1)) {
        this.setState({isValid: false});
        let errorString = this.context.translator.t("You can only load ");
        this.props.extensions.forEach(extension => {
          errorString += extension + " ";
        });
        errorString += this.context.translator.t("files");
        this.setState({extensionError: errorString});
        return;
      }

      this.setState({isValid: true});
      this.setState({extensionError: ""});
      this.setState({type: TYPE_POINT_CLOUD});
      return;
    }

    if (files[0].type.startsWith('image')) {
      this.setState({type: TYPE_IMAGE});
    } else {
      if (files[0].type === 'application/pdf') {
        this.setState({type: TYPE_PDF});
      } else {
        this.setState({type: TYPE_FILE});
      }
    }

    this.setState({isValid: true});
    this.setState({extensionError: ""});
  }

  createFile() {
    this.context.fileActions.createGenericFile(this.props.projectID, this.state.file, this.state.type);
    this.context.appActions.closeForm();
  }

  render() {
    return (
      <div>
        <Dropzone onDrop={(files) => this.changeFile(files)}
                  multiple={false}
                  style={{ margin:'10px', border:'2px dashed rgb(102,102,102)', maxWidth:'800px', height:'100px'}}>
          <div style={{margin:'5px'}}>{this.context.translator.t('Try dropping some files here, or click to select files to upload.')}</div>
        </Dropzone>

        {(this.state.isValid && this.state.type === TYPE_IMAGE) ?
          <div>
            <span>
              <h4>{this.context.translator.t('Chosen file:')}</h4>
            </span>
            <br/>
            <span>
              {this.state.file.name}
              <img src={this.state.file.preview} height="60px" style={{marginLeft:'25px'}}/>
            </span>
          </div> : null}

        {(this.state.isValid && this.state.type !== TYPE_IMAGE) ?
          <div>
            <span><h4>{this.context.translator.t('Chosen file:')}</h4></span>
            <br/>
            <span>
              {this.state.file.name}
            </span>
          </div> : null}

        <div style={{color:'red'}}>
          {this.state.extensionError}
        </div>

        <FlatButton
          label={this.context.translator.t("Create")}
          style={{float:'right'}}
          primary={true}
          disabled={!this.state.isValid}
          onTouchTap={this.createFile}
        />
        <FlatButton
          label={this.context.translator.t("Cancel")}
          style={{float:'right'}}
          primary={true}
          onTouchTap={this.context.appActions.closeForm}
        />
        <br />
      </div>
    );
  }
}

FormUploadFile.propTypes = {
  projectID: PropTypes.string.isRequired,
  extensions: PropTypes.array
};


FormUploadFile.contextTypes = {
  appActions: PropTypes.object.isRequired,
  fileActions: PropTypes.object.isRequired,
  translator: PropTypes.object.isRequired
};


export default FormUploadFile;
