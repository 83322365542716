export default {
  pageStyle: {
    // backgroundColor: '#eee',
    overflowX: 'hidden',
  },
  formStyle: {
    margin: '50px auto',
    height: 250,
    width: '40%',
    textAlign: 'center',
    backgroundColor: '#ddd',
  },
  textStyle: {
    color: '#000',
  },
  floatingLabelStyle: {
    color: '#999'
  },
  hintStyle: {
    color: '#bbb'
  },
  underlineStyle: {
    backgroundColor: '#fff'
  },
  buttonStyle: {
    marginLeft: '50%',
  }
};
