import React from 'react';
import PropTypes from 'prop-types';
import {GridList, GridTile} from 'material-ui/GridList';
import FloatingActionButton from 'material-ui/FloatingActionButton';
import ContentAdd from 'material-ui/svg-icons/content/add';

import Project from './project.jsx'
import Breadcrumb from '../breadcrumb/breadcrumb';

const ProjectsViewer = ({width, height, projects}, {projectActions, appActions, translator, theme}) => {

  let names = [{name: translator.t("Home")}];

  return (
    <div>
      <div style={{
        ...theme.background2Style,
        height: height,
        width: width
      }}/>
      <div style={{
        ...theme.logo1Style,
        width: '135px',
        height: '100px'
      }}/>
      <div style={{
        ...theme.logo2Style,
        width: '300px',
        height: '100px',
      }}/>
      <div style={{
        ...theme.background1Style,
        height: height,
        width: '100%',
      }}/>
      <div style={{height: height, overflowX: 'hidden'}}>
        <br/><Breadcrumb names={names}/>
        <GridList
          cellHeight={185}
          cols={width / 170}
          style={{
            overflowY: 'auto',
            backgroundColor: 'rgba(0,0,0,0)',
            paddingBottom: '40px',
            marginLeft: '20px',
            marginRight: '20px'
          }}
        >

          {
            projects.map((project) => (
              <GridTile key={project._id} style={{overflow: 'visible'}}>
                <Project _id={project._id} name={project.name}/>
              </GridTile>

            ))}

          <GridTile>
            <FloatingActionButton style={{margin: '60px'}} onTouchTap={() => appActions.openCreateProjectForm()}>
              <ContentAdd />
            </FloatingActionButton>
          </GridTile>

        </GridList>
      </div>
    </div>
  );
}

ProjectsViewer.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
};

ProjectsViewer.contextTypes = {
  projectActions: PropTypes.object.isRequired,
  appActions: PropTypes.object.isRequired,
  translator: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};


export default ProjectsViewer;
