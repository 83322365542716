import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

import TextField from 'material-ui/TextField';
import FlatButton from 'material-ui/FlatButton';

const ENTER_KEY = 13;

class FormCreateProject extends React.Component {

  constructor(props, context) {
    super(props, context);
    this.changeName = this.changeName.bind(this);
    this.createProject = this.createProject.bind(this);
    this.state = {isValid: false, nameError: ''};
  }

  componentDidMount() {
    let textField = ReactDOM.findDOMNode(this.refs.textField);
    textField.querySelector("input").focus();
  }

  changeName(newName) {

    this.setState({name: newName});

    if (newName === '') {
      this.setState({isValid: false});
      this.setState({nameError: this.context.translator.t("You must specify a name for the new project")});
      return;
    }

    this.setState({isValid: true});
    this.setState({nameError: ""});
  }


  createProject() {
    this.context.projectActions.callCreateProjectAPI(this.state.name);
    this.context.appActions.closeForm();
  }

  render() {
    return (
      <div>
        <TextField
          ref="textField"
          hintText={this.context.translator.t("Enter name")}
          fullWidth={true}
          errorText={this.state.nameError}
          onChange={event => this.changeName(event.target.value)}
          onKeyDown={event => {if(this.state.isValid && event.keyCode===ENTER_KEY) {this.createProject()}}}
        />
        <br/>
        <FlatButton
          label={this.context.translator.t("Create")}
          style={{float:'right'}}
          primary={true}
          disabled={!this.state.isValid}
          onTouchTap={this.createProject}
        />
        <FlatButton
          label={this.context.translator.t("Cancel")}
          style={{float:'right'}}
          primary={true}
          onTouchTap={this.context.appActions.closeForm}
        />
        <br />
      </div>
    );
  }
}

FormCreateProject.contextTypes = {
  appActions: PropTypes.object.isRequired,
  projectActions: PropTypes.object.isRequired,
  translator: PropTypes.object.isRequired
};


export default FormCreateProject;
