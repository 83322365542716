import React from 'react';
import PropTypes from 'prop-types';
import IconButton from 'material-ui/IconButton';

import fileStyles from './file-style';

import FileIcon from 'material-ui/svg-icons/action/assessment';

const TableFileButton = ({projectID, fileID}, {fileActions, appActions, translator}) => (
  <IconButton
    onTouchTap={() => {fileActions.callOpenTableAPI(projectID, fileID);}}
    style={fileStyles.bigButton}
    iconStyle={fileStyles.bigIcon}
    tooltip={translator.t("Open table")}
    tooltipStyles={fileStyles.tooltipStyle}
  >
    <FileIcon />
  </IconButton>
);

TableFileButton.propTypes = {
  projectID: PropTypes.string.isRequired,
  fileID: PropTypes.string.isRequired,
};

TableFileButton.contextTypes = {
  fileActions: PropTypes.object.isRequired,
  appActions: PropTypes.object.isRequired,
  translator: PropTypes.object.isRequired
};


export default TableFileButton;
