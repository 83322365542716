import React from 'react';
import ReactDOM from 'react-dom';
import MetiorDashboard from './metior-dashboard.jsx';

import config from '../config/metior';
import theme from '../config/theme/theme';

ReactDOM.render(
  <MetiorDashboard config={config} theme={theme}/>,
  document.getElementById('app')
);
