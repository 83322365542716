let custom_theme;
try {
  custom_theme = require('./custom_theme');
} catch (err) {
  custom_theme = {};
}

let theme = {...require('./default_theme'), ...custom_theme};

console.log(theme, custom_theme, require('./default_theme'));
export default theme;
