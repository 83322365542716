import React from 'react';
import PropTypes from 'prop-types';

import RaisedButton from 'material-ui/RaisedButton';
import Popover from 'material-ui/Popover';
import Menu from 'material-ui/Menu';
import MenuItem from 'material-ui/MenuItem';
import Avatar from 'material-ui/Avatar';
import LogoutIcon from 'material-ui/svg-icons/action/exit-to-app';
import SettingsIcon from 'material-ui/svg-icons/action/settings';
import ProfileIcon from 'material-ui/svg-icons/action/face';

import {USERBAR_OPEN} from '../../constants';

const UserBar = ({mode, user}, {appActions, userActions, translator}) => {
  let avatarName = "";
  user.username.split(" ").forEach(name => {
    avatarName += name[0];
  });
  return (
    <div>
      <div id="PopoverAnchor" style={{marginTop:'5px'}}>
        <RaisedButton
          label={user.username}
          onTouchTap={appActions.changeUserbarStatus}
          icon={<Avatar size={35}>{avatarName.toUpperCase()}</Avatar>}
        />
      </div>
      <Popover
        open={mode === USERBAR_OPEN}
        anchorEl={document.getElementById('PopoverAnchor')}
        anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
        targetOrigin={{horizontal: 'right', vertical: 'top'}}
        onRequestClose={appActions.changeUserbarStatus}
      >
        <Menu>
          <MenuItem leftIcon={<LogoutIcon/>} primaryText={translator.t("Sign out")} onClick={userActions.userLogout}/>
        </Menu>
      </Popover>
    </div>
  )
};


UserBar.propTypes = {
  mode: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
};

UserBar.contextTypes = {
  appActions: PropTypes.object,
  userActions: PropTypes.object,
  translator: PropTypes.object
};

export default UserBar

