import React from 'react';
import PropTypes from 'prop-types';
import Snackbar from 'material-ui/Snackbar';

import {SNACKBAR_OPEN} from "../../constants";

const AppSnackbar = ({mode}, {appActions}) => (
  <Snackbar
    open={mode === SNACKBAR_OPEN}
    bodyStyle={{backgroundColor: '#ccc'}}
    message="Project successfully shared"
    autoHideDuration={4000}
    onRequestClose={appActions.changeSnackbarStatus}
  />
);

AppSnackbar.propTypes = {
  mode: PropTypes.string.isRequired,
};

AppSnackbar.contextTypes = {
  appActions: PropTypes.object
};

export default AppSnackbar;
