import React from 'react';
import AppBarMetior from './appbar/appbar-metior.jsx'
import Sidebar from './sidebar/sidebar.jsx'
import Content from './content.jsx';
import FormDialog from './forms/form-dialog.jsx';
import AppSnackbar from './app-snackbar/app-snackbar.jsx';

export default function Layout({width, height, state}) {

  let barHeight = 64; // Default for material-ui
  let viewerHeight = height - barHeight;

  return (
    <div>
      <AppBarMetior mode={state.mode} session={state.session} user={state.user}/>
      <Content width={width}
               height={viewerHeight}
               mode={state.mode}
               page={state.page}
               projects={state.projects}
               currentProject={state.currentProject}
               currentFile={state.currentFile}/>
      <FormDialog mode={state.mode} requestedDialog={state.requestedDialog} dialogProperties={state.dialogProperties}/>
      <AppSnackbar mode={state.mode}/>

    </div>
  );
}
