import React from 'react';
import PropTypes from 'prop-types';

import AppBar from 'material-ui/AppBar';
import IconButton from 'material-ui/IconButton';
import HelpIcon from 'material-ui/svg-icons/action/help';

import UserBar from '../userbar/userbar.jsx'
import docLocation from '../../docs/MANUALE_BaM_v.2_VERSIONE_BLIND.PDF';

const AppBarMetior = ({mode, user, session}, {appActions, translator}) => {

  let iconStyleLeft = {};
  let iconStyleRight = {};
  let title = translator.t("Dashboard");
  if (session.token === "") {
    iconStyleLeft = {display: 'none'};
    iconStyleRight = {display: 'none'};
    title = "";
  }
  return (
    <AppBar
      title={title}
      style={{backgroundColor:'black',
            color:'#fff',
            height:'90%'}}
      titleStyle={{color:'#fff'}}
      iconStyleLeft={iconStyleLeft}
      iconStyleRight={iconStyleRight}
      showMenuIconButton={false}
      iconElementRight={
        <table>
          <tbody>
          <tr>
            <td>
              <IconButton
                onClick={() => window.open(docLocation)}
                iconStyle={{color: '#909090'}}
                tooltip={translator.t("BaM Manual")}
              >
                <HelpIcon/>
              </IconButton>
            </td>
            <td>
            <UserBar mode={mode} user={user}/>
            </td>
          </tr>
          </tbody>
        </table>
    }
    />
  )
};

AppBarMetior.propTypes = {
  mode: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  session: PropTypes.object.isRequired,
};

AppBarMetior.contextTypes = {
  appActions: PropTypes.object,
  translator: PropTypes.object
};

export default AppBarMetior;
