import {List} from "immutable";

import {
  SAVE_PROJECTS,
  GET_PROJECT,
  CREATE_PROJECT,
  DELETE_PROJECT,
  SHARE_PROJECT,
  CLONE_PROJECT,
  UPDATE_PROJECT,
  SNACKBAR_OPEN,
  FILES_PAGE,
  DISPLAY_PROJECT
} from '../constants';

import {Project, File} from '../models'

export default function projectReducer(state, action) {

  let newProject;

  switch (action.type) {

    case SAVE_PROJECTS:

      let projects = new List();
      action.projects.forEach(project => {

        newProject = new Project({
          _id: project._id,
          name: project.name,
          owner: project.owner,
          created: project.created,
          modified: project.modified
        });

        projects = projects.push(newProject);

      });

      return state.set('projects', projects);

    case CREATE_PROJECT:
    case CLONE_PROJECT:

      newProject = new Project({
        _id: action.project._id,
        name: action.project.name,
        owner: action.project.owner,
        created: action.project.created,
        modified: action.project.modified
      });

      return state.update('projects', projects => {
        return projects.push(newProject);
      });

    case GET_PROJECT:

      let filesList = new List();
      action.project.files.forEach(file => {
        filesList = filesList.push(new File(file).set('fileSize', file.size));
      });

      newProject = new Project({
        _id: action.project._id,
        name: action.project.name,
        owner: action.project.owner,
        created: action.project.created,
        modified: action.project.modified,
        files: filesList
      });

      return state.set('currentProject', newProject);

    case DELETE_PROJECT:
      return state.update('projects', projects => {
        return projects.filter(project => {
          return project._id != action.projectID
        });
      });
      return state;

    case SHARE_PROJECT:
      if (action.data.success) {
        return state.set('mode', SNACKBAR_OPEN);
      }
      return state;
    case UPDATE_PROJECT:
      return state.update('projects', projects => state.projects.update(
        state.projects.findIndex(function (project) {
          return project._id === action.project._id;
        }), function (project) {
          return project
            .set('name', action.project.name)
            .set('created', action.project.created)
            .set('modified', action.project.modified)
            .set('_id', action.project._id)
            .set('owner', action.project.owner);
        }
      ));

    case DISPLAY_PROJECT:
      return (projectReducer(state, {
        type: GET_PROJECT,
        project: action.project
      }))
        .set('page', FILES_PAGE);
    default:
      return state;

  }
}

