export default {
  bigButton: {
    width: 75,
    height: 75,
    marginLeft: '38px'
  },
  littleButton: {
    width: 32,
    height: 32
  },
  littleIcon: {
    width: 20,
    height: 20,
    fill: '#959595'
  },
  bigIcon: {
    width: 50,
    height: 50,
    fill: '#959595'
  },
  fileStyle: {
    backgroundColor: '#ddd',
    width: 150,
    height: 150,
    marginLeft: '15px',
    marginRight: '15px',
    borderRadius: '10px',
    color: '#000'
  },
  fileTitleContainer: {
    textAlign: 'center',
    fontSize: '13px',
    overflow: 'auto',
    height: '45px',
    lineHeight: '45px'
  },
  fileTitle: {
    verticalAlign: 'middle',
    display: 'inline-block',
    lineHeight: 'normal'
  },
  buttonBar: {
    //marginLeft: '20px',
    marginTop: '-5px',
    textAlign:'center'
  },
  tooltipStyle: {
    borderRadius: '5px'
  }

};
