import {Map} from 'immutable';

import {
  USERBAR_OPEN,
  IDLE,
  SIDEBAR_OPEN,
  DIALOG_OPEN,
  SNACKBAR_OPEN,
  ADD_FILE_BAR_OPEN,
  CHANGE_USERBAR_STATUS,
  CHANGE_SIDEBAR_STATUS,
  CHANGE_SNACKBAR_STATUS,
  CHANGE_PAGE,
  CHANGE_FORM_STATUS,
  CHANGE_ADD_FILE_BAR_STATUS
} from '../constants'

export default function (state, action) {

  let dialogProperties;

  switch (action.type) {

    case CHANGE_USERBAR_STATUS:
      if (state.mode !== USERBAR_OPEN) {
        return state.set('mode', USERBAR_OPEN);
      }
      return state.set('mode', IDLE);

    case CHANGE_SIDEBAR_STATUS:
      if (state.mode !== SIDEBAR_OPEN) {
        return state.set('mode', SIDEBAR_OPEN);
      }
      return state.set('mode', IDLE);

    case CHANGE_SNACKBAR_STATUS:
      if (state.mode !== SNACKBAR_OPEN) {
        return state.set('mode', SNACKBAR_OPEN);
      }
      return state.set('mode', IDLE);
    
    case CHANGE_ADD_FILE_BAR_STATUS:
      if (state.mode !== ADD_FILE_BAR_OPEN) {
        return state.set('mode', ADD_FILE_BAR_OPEN);
      }
      return state.set('mode', IDLE);

    case CHANGE_PAGE:
      return state.set('page', action.page);

    case CHANGE_FORM_STATUS:

      if (state.mode !== DIALOG_OPEN) {
        return state.set('mode', DIALOG_OPEN)
          .set('requestedDialog', action.requestedDialog)
          .set('dialogProperties', new Map(action.dialogProps));
      }
      return state.set('mode', IDLE);

    default:
      return state;
  }
}
