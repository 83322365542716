import * as constants from '../constants';
import {State} from '../models';

import projectReducer from './project-reducer';
import fileReducer from './file-reducer';
import appReducer from './app-reducer';
import userReducer from './user-reducer';

export const initialState = new State();

export default function appReducers(state, action) {
  state = state || initialState;

  switch (action.type) {

    case constants.CREATE_PROJECT:
    case constants.SAVE_PROJECTS:
    case constants.GET_PROJECT:
    case constants.DELETE_PROJECT:
    case constants.SHARE_PROJECT:
    case constants.CLONE_PROJECT:
    case constants.UPDATE_PROJECT:
    case constants.DISPLAY_PROJECT:
      return projectReducer(state, action);

    case constants.CREATE_FILE:
    case constants.GET_FILE:
    case constants.GET_FILES:
    case constants.DELETE_FILE:
    case constants.UPDATE_FILE:
    case constants.GET_FILE_THUMBNAIL:
    case constants.DOWNLOAD_FILE:
    case constants.UPDATE_FILE_DATA:
    case constants.DISPLAY_FILE:
    case constants.SAVE_FILES_INTO_STATE:
      return fileReducer(state, action);

    case constants.CHANGE_USERBAR_STATUS:
    case constants.CHANGE_SIDEBAR_STATUS:
    case constants.CHANGE_SNACKBAR_STATUS:
    case constants.CHANGE_ADD_FILE_BAR_STATUS:
    case constants.CHANGE_PAGE:
    case constants.CHANGE_FORM_STATUS:
      return appReducer(state, action);

    case constants.SAVE_SESSION:
    case constants.SAVE_USER:
    case constants.CLEAR_SESSION:
    case constants.CLEAR_USER:
    case constants.REGISTER_USER:
      return userReducer(state, action);

    case constants.SAVE_USER_AND_GO_TO_FILES_PAGE:
    case constants.GO_TO_PROJECTS_PAGE:
    case constants.GO_TO_FILES_PAGE:
    case constants.LOGOUT_AND_GO_TO_LOGIN_PAGE:
    case constants.REDIRECT_TO_LOGIN_PAGE:
      return action.actions.reduce(appReducers, state);

    default:
      return state;
  }
};
